import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import { apiList } from "../../../Common/Api/ApiList";
import Loader from "../../../Common/Loader";
import NoDataImg from "../../../Common/NoData";
import RatingStar from "../../../Component/Rating";
import userImg from "../../../Assets/image/user/9720009.jpg";
import moment from "moment";
import FetchSignedUrl from "../../../Component/URL_Generator";

export default function RatingTabpanel({ id }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [imgURLs, setImgURLs] = useState([]);

  const fetchData = async (count = 0) => {
    try
    {
      setLoading(true);
      const res = await AxiosInstance.get(
        `${apiList.TrainersDetails2}?t_id=${id}&type=2&count=${count}`
      );
      if (res?.data?.s === 1)
      {
        const newData = res?.data?.r?.data;
        setData((prevData) => (count === 0 ? newData : [...prevData, ...newData]));
        setHasMore(newData.length > 0);
      } else
      {
        setHasMore(false);
      }
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    setData([]);
    setCount(0);
    setHasMore(true);
    fetchData(0);
  }, [id]);

  useEffect(() => {
    const fetchSignedUrls = async () => {
      try
      {
        const newImgURLs = await Promise.all(
          data.map(async (x) => {
            const objectKey = x?.profile_img?.split("https://instatrainme-api.s3.amazonaws.com/")[1];
            return objectKey ? await FetchSignedUrl({ path: objectKey }) : null;
          })
        );
        setImgURLs(newImgURLs);
      } catch (error)
      {
        console.error("Error fetching signed URLs", error);
      }
    };

    if (data.length > 0)
    {
      fetchSignedUrls();
    }
  }, [data]);

  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        height: { md: "calc(100vh - 330px)" },
        overflow: { md: "scroll" },
        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          p: "1rem",
          background: "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          color: "white",
          height: "50px",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          position: "sticky",
          top: "0",
        }}
      >
        Rating & Review
      </Typography>
      <Box
        sx={{
          p: 2,
          minHeight: "200px",
          height: "calc(100% - 50px)",
          overflow: "scroll",
        }}
        className="HideScrollBar"
      >
        {loading && count === 0 ? (
          <Loader />
        ) : data.length > 0 ? (
          <Box>
            {data.map((x, i) => (
              <Box
                key={i}
                sx={{
                  p: 2,
                  borderRadius: "8px",
                  boxShadow: "rgba(0, 0, 0, 0.24) 1px 1px 10px -4px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "start",
                  mb: "0.5rem",
                }}
              >
                <Box sx={{ display: "flex", gap: "0.7rem", width: "100%" }}>
                  <Box>
                    <img
                      src={imgURLs[i] || userImg} // Use the signed URL or fallback to default image
                      style={{
                        borderRadius: "12px",
                        height: "50px",
                        width: "50px",
                        objectFit: "cover",
                        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.54)",
                      }}
                      alt={`${x?.first_name} ${x?.last_name}`}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        fontWeight: "600",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1rem",
                      }}
                    >
                      <Box>{`${x?.first_name} ${x?.last_name}`}</Box>
                      <Box sx={{ opacity: "0.7" }}>{moment(x?.created_at).fromNow()}</Box>
                    </Box>
                    <Box>
                      <RatingStar rating={x?.rating_point} />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      color: `rgba(0, 0, 0, 0.8)`,
                      fontSize: "16px",
                      textAlign: "start",
                    }}
                  >
                    {x?.review}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
            <NoDataImg />
          </Box>
        )}
        {loading && count > 0 && <CircularProgress />}
      </Box>
    </Box>
  );
}
