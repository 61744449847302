import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { toast } from "react-toastify";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import StepperComponent from "./StepperComponent";
import Loader from "../../Common/Loader";
import { PathList } from "../../Common/Routes/Path";
import ErrorToaster from "../../Component/ErrorToaster";
import moment from "moment";

export default function BookingDetaileScreen() {
  const { id } = useParams();
  const theme = useTheme();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [address, setaddress] = useState();
  // const [stripeCharge, setstripeCharge] = useState(0);

  const GetAddressFromLng = async (lat, lng) => {
    try
    {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxwKI7SmYgpPKeO2-vkOda_SDEdqgzYyE`
      );
      const data = await response?.json();
      if (data?.status === "OK")
      {
        return setaddress(data?.results[0]?.formatted_address);
      } else
      {
        throw new Error("Failed to fetch address");
      }
    } catch (error)
    {
      console.log(error.message);
      return null;
    }
  };

  // const FetchStripeCharge = async () => {
  //   try {
  //     const res = await AxiosInstance.get(apiList.getStripeCharge);
  //     if (res.data.s === 1 && res.data.r.status === 1) {
  //       setstripeCharge(res.data.r.charge);
  //     } else {
  //       toast.error(res.data.m);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    if (data?.trainingDetails?.location.to_lat)
    {
      GetAddressFromLng(
        data?.trainingDetails?.location.to_lat,
        data?.trainingDetails?.location.to_lang
      );
    }
  }, [data]);
  const getPreferance = (data) => {
    if (data?.training_type === 1 && data?.training_preference === 1)
    {
      return "Virtual Instant Training";
    } else if (data?.training_type === 1 && data?.training_preference === 2)
    {
      return "Instant Onsite(At user location)";
    } else if (data?.training_type === 1 && data?.training_preference === 3)
    {
      return "Instant Onsite(At trainer location)";
    } else if (data?.training_type === 2 && data?.training_preference === 1)
    {
      return "Virtual Future Training";
    } else if (data?.training_type === 2 && data?.training_preference === 2)
    {
      return "Future Onsite (At user location)";
    } else if (data?.training_type === 2 && data?.training_preference === 3)
    {
      return "Future Onsite (At trainer location)";
    }
  };

  const userDetails = [
    { title: "Name", text: data?.userDetails?.name },
    { title: "Email", text: data?.userDetails?.email },
    {
      title: "Training Type",
      text:
        (data?.userDetails?.training_type === 1 && "Instant training") ||
        (data?.userDetails?.training_type === 2 && "Virtual training"),
    },
    {
      title: "Training Preferance",
      text: getPreferance(data?.userDetails),
    },

    { title: "Category", text: data?.userDetails?.category },
    { title: "Sub Category", text: data?.userDetails?.sub_category },
    {
      title: "Date",
      text: moment
        .utc(data?.userDetails?.booking_date)
        .local()
        .format("ll"),
    },
    {
      title: "Time",
      text: `${moment
        .utc(data?.userDetails?.from_time, "HH:mm:ss")
        .local()
        .format("hh:mm A")} - ${moment
          .utc(data?.userDetails?.to_time, "HH:mm:ss")
          .local()
          .format("hh:mm A")}`,
    },
  ];

  const TrainerDetails = [
    { title: "Trainer Name", text: data?.trainingDetails?.trainer_name },
    { title: "Rating", text: data?.trainingDetails?.rating_point },
    { title: "Bio", text: data?.trainingDetails?.bio },
    { title: "Session Rate", text: `$${data?.trainingDetails?.session_rate}` },
  ];

  const SessionDetails = [
    { title: "Title", text: data?.session_form?.title ?? "-" },
    { title: "Category", text: data?.userDetails?.category ?? "-" },
    { title: "Description", text: data?.session_form?.description ?? "-" },
    { title: "Note", text: data?.session_form?.note ?? "-" },
  ];
  const TrainingPlanDetails = data?.training_plan?.id
    ? [
      {
        title: "Start Date",
        text: data?.training_plan?.start_date
          ? moment(data?.training_plan?.start_date).format("ll")
          : "-",
      },
      {
        title: "End Date",
        text: data?.training_plan?.end_date
          ? moment(data?.training_plan?.end_date).format("ll")
          : "-",
      },
      {
        title: "Price",
        text: data?.training_plan?.training_plan_price
          ? "$" + data?.training_plan?.training_plan_price
          : "-",
      },
      { title: "Category", text: data?.training_plan?.cat_name ?? "-" },
      {
        title: "Sub Category",
        text: data?.training_plan?.sub_cat_name ?? "-",
      },
    ]
    : [];
  // const TrainingPlanDetails = data?.is_training_plan
  //   ? [
  //       { title: "Title", text: data?.session_form?.title ?? "-" },
  //       { title: "Category", text: data?.userDetails?.category ?? "-" },
  //       { title: "Description", text: data?.session_form?.description ?? "-" },
  //       { title: "Note", text: data?.session_form?.note ?? "-" },
  //     ]
  //   : [];

  data?.userDetails?.training_preference !== 1 &&
    TrainerDetails.push({ title: "Location", text: address });

  data?.trainingDetails?.is_completed === 1 &&
    data?.trainingDetails?.reason &&
    TrainerDetails.push({
      title: "Complete Reason",
      text: `${data?.trainingDetails?.reason}`,
    });

  const fetchData = async () => {
    try
    {
      const res = await AxiosInstance.get(
        `${apiList.GetBookingDetailsById}?id=${id}`
      );
      if (res?.data?.s === 1 && res?.data?.r)
      {
        setData(res?.data?.r);
      } else
      {
        toast.error(res?.data?.m);
      }
      setIsLoading(false);
    } catch (error)
    {
      setIsLoading(false);
      <ErrorToaster error={error} />;
    }
  };

  useEffect(() => {
    // FetchStripeCharge();
    fetchData(); //eslint-disable-next-line
  }, [id]);

  return (
    <Box sx={{ width: "100%", minHeight: `calc(100vh - 77px)`, pb: 4 }}>
      {isLoading ? (
        <Box sx={{ width: "100%", height: `calc(100vh - 77px)`, pb: 4 }}>
          <Loader />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ fontWeight: "600" }}>Details {`#${id}`}</Box>
          </Box>
          <Box
            sx={{
              m: "1rem 0",
              boxShadow: "3px 3px 7px 0 #00000040",
              borderRadius: "5px",
            }}
          >
            <StepperComponent data={data && data?.order_process_status} />
          </Box>
          <Box
            sx={{
              m: "1rem 0",
              boxShadow: "3px 3px 7px 0 #00000040",
              borderRadius: "5px",
              background: "#efb2b2",
              display:
                data?.trainingDetails?.is_completed === -1 ? "flex" : "none",
            }}
          >
            <Box
              sx={{
                display: data?.trainingDetails?.reason ? "flex" : "none",
                width: "100%",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  // background: theme.palette.background.thead,
                  background: "#e29a9a",
                  width: { xs: "100%", md: "200px" },
                  padding: "10px",
                  borderRadius: "5px",
                  borderBottom: "1px solid white",
                  fontWeight: "600",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Cancel Reason
              </Box>
              <Box
                sx={{
                  width: { xs: "100%", md: "calc(100% - 200px)" },
                  padding: "10px",
                  height: "100%",
                  fontWeight: "500",
                  borderBottom: `1px solid ${theme.palette.background.thead}`,
                }}
              >
                {data?.trainingDetails?.reason}
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "column", lg: "row" },
              justifyContent: "center",
              gap: "1rem",
              pb: "1rem",
            }}
          >
            <Box
              sx={{
                width: { sm: "100%", lg: "33%" },
                boxShadow: "3px 3px 7px 0 #00000040",
                overflow: "hidden",
                borderRadius: "9px",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.Blend,
                  width: "100%",
                  color: "white",
                  padding: "10px",
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                User Details
              </Box>
              {userDetails &&
                userDetails?.map((x, i) => {
                  return (
                    <Box key={i} sx={{ display: "flex", width: "100%" }}>
                      <Box
                        sx={{
                          background: theme.palette.background.thead,
                          width: "30%",
                          padding: "10px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        {x?.title}
                      </Box>
                      <Box
                        sx={{
                          width: "70%",
                          padding: "10px",
                          fontWeight: "600",
                          borderBottom: `1px solid ${theme.palette.background.thead}`,
                        }}
                      >
                        {x?.title === "Name" ? (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box>{x?.text}</Box>
                              <Box
                                sx={{
                                  fontWeight: "400",
                                  textDecoration: "underline",
                                  color: "#3C75D4FA",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(
                                    `${PathList.UserDetails}/${data?.userDetails?.user_id}`
                                  )
                                }
                              >
                                Details
                              </Box>
                            </Box>
                          </>
                        ) : (
                          x?.text
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Box
              sx={{
                width: { sm: "100%", lg: "33%" },
                boxShadow: "3px 3px 7px 0 #00000040",
                overflow: "hidden",
                borderRadius: "9px",
                height: "fit-content",
              }}
            >
              <Box
                sx={{
                  background: theme.palette.primary.Blend,
                  width: "100%",
                  color: "white",
                  padding: "10px",
                  borderTopLeftRadius: "9px",
                  borderTopRightRadius: "9px",
                }}
              >
                Training Details
              </Box>
              {TrainerDetails &&
                TrainerDetails?.map((x, i) => {
                  return (
                    <Box key={i} sx={{ display: "flex", width: "100%" }}>
                      <Box
                        sx={{
                          background: theme.palette.background.thead,
                          width: "30%",
                          padding: "10px",
                          borderBottom: "1px solid white",
                        }}
                      >
                        {x?.title}
                      </Box>
                      <Box
                        sx={{
                          width: "70%",
                          padding: "10px",
                          fontWeight: "600",
                          borderBottom: `1px solid ${theme.palette.background.thead}`,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {x?.title === "Trainer Name" ? (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Box>{x?.text}</Box>
                              <Box
                                sx={{
                                  fontWeight: "400",
                                  textDecoration: "underline",
                                  color: "#3C75D4FA",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  navigate(
                                    `${PathList.TrainerDetails}/${data?.trainingDetails?.trainer_id}`
                                  )
                                }
                              >
                                Details
                              </Box>
                            </Box>
                          </>
                        ) : x?.title === "Location" ? (
                          <Typography
                            sx={{
                              cursor: "pointer",
                              fontWeight: "600",
                              "&:hover": {
                                textDecoration: "underline",
                                color: "#3f3fcf",
                              },
                            }}
                            onClick={() => {
                              window.open(
                                "https://www.google.com/maps/search/" + address
                              );
                            }}
                          >
                            {address}
                          </Typography>
                        ) : (
                          x?.text
                        )}
                        {x?.title === "Rating" && (
                          <StarRoundedIcon sx={{ color: "#F5CD00" }} />
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Box
              sx={{
                width: { sm: "100%", lg: "33%" },
                height: "fit-content",
                display: "flex",
                gap: 2,
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  width: { sm: "100%" },
                  boxShadow: "3px 3px 7px 0 #00000040",
                  overflow: "hidden",
                  borderRadius: "9px",
                  height: "fit-content",
                }}
              >
                <Box
                  sx={{
                    background: theme.palette.primary.Blend,
                    width: "100%",
                    color: "white",
                    padding: "10px",
                    borderTopLeftRadius: "9px",
                    borderTopRightRadius: "9px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>Payment Details</Box>
                  <Box>
                    {/*  */}
                    {data?.payment_details?.is_payment === 1 ? (
                      <>
                        <span
                          style={{
                            color: "greenYellow",
                            background: "#ffffff45",
                            letterSpacing: "1px",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            padding: "5px 10px",
                            borderRadius: "9px",
                          }}
                        >
                          PAID{" "}
                        </span>
                      </>
                    ) : data?.payment_details?.is_payment === 0 ? (
                      <>
                        <span
                          style={{
                            color: "#EB0000",
                            background: "#ffffff45",
                            letterSpacing: "1px",
                            fontWeight: "bold",
                            fontSize: "1rem",
                            padding: "5px 10px",
                            borderRadius: "9px",
                          }}
                        >
                          UNPAID
                        </span>
                      </>
                    ) : (
                      data?.payment_details?.is_payment === -1 && (
                        <>
                          <span
                            style={{
                              color: "greenYellow",
                              background: "#ffffff45",
                              letterSpacing: "1px",
                              fontWeight: "bold",
                              fontSize: "1rem",
                              padding: "5px 10px",
                              borderRadius: "9px",
                            }}
                          >
                            REFUND
                          </span>
                        </>
                      )
                    )}
                  </Box>
                </Box>
               

                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    borderTop: "1px solid #dcdcdc",
                  }}
                >
                  <Box
                    sx={{
                      background: theme.palette.background.thead,
                      width: "30%",
                      padding: "10px",
                      borderBottom: "1px solid white",
                    }}
                  >
                    Final Amount
                  </Box>
                  <Box
                    sx={{
                      width: "70%",
                      padding: "10px",
                      borderBottom: `1px solid ${theme.palette.background.thead}`,
                      display: "flex",
                      alignItems: "center",
                      fontWeight: "600",
                    }}
                  >
                    ${data?.payment_details?.total_amount}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: { sm: "100%" },
                  boxShadow: "3px 3px 7px 0 #00000040",
                  overflow: "hidden",
                  borderRadius: "9px",
                  height: "fit-content",
                  display: TrainingPlanDetails?.length > 0 ? "block" : "none",
                }}
              >
                <Box
                  sx={{
                    background: theme.palette.primary.Blend,
                    width: "100%",
                    color: "white",
                    padding: "10px",
                    borderTopLeftRadius: "9px",
                    borderTopRightRadius: "9px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>Training Plan Details</Box>
                  <Box
                    onClick={() =>
                      navigate(`${PathList.PlanDetails}`, {
                        state: {
                          planId: data?.training_plan?.id,
                          From: "/booking",
                        },
                      })
                    }
                    sx={{
                      background: "#FFF",
                      color: "#000",
                      borderRadius: "6px",
                      px: 1,
                      cursor: "pointer",
                    }}
                  >
                    View
                  </Box>
                </Box>

                {TrainingPlanDetails &&
                  TrainingPlanDetails?.map((x, i) => {
                    return (
                      <Box key={i} sx={{ display: "flex", width: "100%" }}>
                        <Box
                          sx={{
                            background: theme.palette.background.thead,
                            width: "30%",
                            padding: "10px",
                            borderBottom: "1px solid white",
                          }}
                        >
                          {x?.title}
                        </Box>
                        <Box
                          sx={{
                            width: "70%",
                            padding: "10px",
                            fontWeight: "600",
                            borderBottom: `1px solid ${theme.palette.background.thead}`,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {x?.text}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          </Box>

          {/*  Session form details */}

          <Box
            sx={{
              width: { sm: "100%" },
              boxShadow: "3px 3px 7px 0 #00000040",
              overflow: "hidden",
              borderRadius: "9px",
              height: "fit-content",
            }}
          >
            <Box
              sx={{
                background: theme.palette.primary.Blend,
                width: "100%",
                color: "white",
                padding: "10px",
                borderTopLeftRadius: "9px",
                borderTopRightRadius: "9px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>Session Form Details</Box>
            </Box>

            {SessionDetails &&
              SessionDetails.map((e, i) => {
                return (
                  <Box
                    key={"session-" + i}
                    sx={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        background: theme.palette.background.thead,
                        width: "160px",
                        padding: "10px",
                        borderBottom: "1px solid white",
                      }}
                    >
                      {e?.title}
                    </Box>
                    <Box
                      sx={{
                        width: "70%",
                        padding: "10px",
                        borderBottom: `1px solid ${theme.palette.background.thead}`,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "600",
                      }}
                    >
                      {e?.text}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </>
      )}
    </Box>
  );
}
