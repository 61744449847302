import {
  Box,
  Button,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import notificationicon from "../../Assets/image/Notifications/Group6.png";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../../Common/Loader";
import NoDataImg from "../../Common/NoData";
import ErrorToaster from "../../Component/ErrorToaster";

const NotificationScreen = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [notificationsList, setNotificationsList] = useState([]);
  const theme = useTheme();
  const [isDisable, setIsDisable] = useState(true);
  const initial = {
    Title: "",
    Description: "",
    Select: 2,
    SendDate: moment().format("YYYY/MM/DD"),
    SendTime: moment().format("HH:mm:ss"),
    type: 1,
  };
  const [data, setData] = useState(initial);

  const [errors, setErrors] = useState({
    Title: "",
    Description: "",
    Select: "",
    SendDate: "",
    SendTime: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!data.Title) newErrors.Title = "Title is required";
    if (!data.Description) newErrors.Description = "Description is required";
    if (!data.Select) newErrors.Select = "Select is required";
    if (!isDisable) {
      if (!data?.SendDate) newErrors.SendDate = "Date is required";
      if (!data?.SendTime) newErrors.SendTime = "Time is required";
      if (moment(data?.SendDate).subtract(-1, "day").isBefore())
        newErrors.SendDate = "Invalid Date";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    } else {
      setErrors({});
    }

    try {
      const response = await AxiosInstance.post(apiList?.SendNotifications, {
        title: data.Title,
        description: data.Description,
        notification_type: data.Select,
        sent_date: data.SendDate,
        sent_time: data.SendTime,
        user_type: data.type,
      });
      if (response.data.s === 1) {
        toast.success("success");
        fetchNotificationsHistory();
      } else {
        toast.error(response.data.m);
      }
    } catch (error) {
      <ErrorToaster error={error} />;
    }
    setData(initial);
    setIsDisable(true);
  };

  const handleChange = (e, type) => {
    if (type === "time") {
      if (data?.Select == 1) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    setErrors((prev) => ({
      ...prev,
      [e?.target?.name]: "",
    }));
  };
  const handleNotificatinForChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    setErrors((prev) => ({
      ...prev,
      [e?.target?.name]: "",
    }));
  };

  const fetchNotificationsHistory = async () => {
    try {
      const res = await AxiosInstance.get(apiList.GetNotificationsList);
      res?.data?.r?.length > 0 && setNotificationsList(res?.data?.r);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationsHistory();
  }, []);

  const byDate = (notificationsList || []).reduce((obj, item) => {
    const messageDate = moment(item?.updated_at);
    let displayDate;

    if (messageDate.isSame(moment(), "day")) {
      displayDate = "Today";
    } else if (messageDate.isSame(moment().subtract(1, "days"), "day")) {
      displayDate = "Yesterday";
    } else {
      displayDate = messageDate.format("ll");
    }

    if (obj[displayDate]) {
      obj[displayDate].push(item);
    } else {
      obj[displayDate] = [{ ...item }];
    }

    return obj;
  }, {});

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "calc(100vh - 106px)",
          gap: "2rem",
          // border: "solid red",
          flexDirection: { xs: "column", lg: "row" },
          // position: "absolute",
          // top: "0",
        }}
      >
        <Box sx={{ width: { xs: "100%", lg: "60%" }, py: 1 }}>
          {/* <Typography variant="h6" sx={{ fontSize: "28px", fontWeight: "500" }}>
            Notifications
          </Typography> */}
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <Typography sx={{ fontSize: "18px", fontWeight: "500", mb: 1 }}>
              Notification Title
            </Typography>
            <input
              type="text"
              name="Title"
              value={data?.Title}
              onChange={handleChange}
              placeholder="Eg :- Gym Time Now"
              style={{
                width: "100%",
                height: "45px",
                borderRadius: "10px",
                border: "none",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                paddingLeft: "15px",
                fontSize: "16px",
                outline: "none",
              }}
            />
            {errors.Title && (
              <Typography sx={{ color: "red", fontSize: "14px" }}>
                {errors.Title}
              </Typography>
            )}

            <Typography
              sx={{ fontSize: "18px", fontWeight: "500", mt: 3, mb: 1.5 }}
            >
              Description
            </Typography>
            <textarea
              type="text"
              name="Description"
              onChange={handleChange}
              value={data.Description}
              placeholder="Description"
              style={{
                width: "100%",
                minHeight: "150px",
                paddingTop: "15px",
                borderRadius: "10px",
                border: "none",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                paddingLeft: "15px",
                fontSize: "16px",
                outline: "none",
              }}
            />
            {errors.Description && (
              <Typography sx={{ color: "red", fontSize: "14px" }}>
                {errors.Description}
              </Typography>
            )}

            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "space-between",
                alignItems: "centre",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "500", mt: 3, mb: 1.5 }}
                >
                  Select
                </Typography>
                <Select
                  name="Select"
                  value={data.Select}
                  onChange={(e) => handleChange(e, "time")}
                  defaultValue={2}
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    ".MuiSelect-select": {
                      padding: "10px 12px",
                    },
                  }}
                >
                  {["Schedule", "Now"].map((x, i) => {
                    return (
                      <MenuItem
                        key={i}
                        value={i + 1}
                        style={{ display: x === "None" ? "none" : "block" }}
                      >
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.Select && (
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {errors.Select}
                  </Typography>
                )}
              </Box>{" "}
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "500", mt: 3, mb: 1.5 }}
                >
                  Notification For
                </Typography>
                <Select
                  name="type"
                  value={data.type}
                  onChange={handleNotificatinForChange}
                  fullWidth
                  sx={{
                    borderRadius: "8px",
                    ".MuiSelect-select": {
                      padding: "10px 12px",
                    },
                  }}
                >
                  {["All", "Users", "Trainers"].map((x, i) => {
                    return (
                      <MenuItem key={i} value={i + 1}>
                        {x}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.type && (
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {errors.type}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                // display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 3,
                display: isDisable ? "none" : "flex",
              }}
            >
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "500", mt: 3, mb: 1.5 }}
                >
                  Select Date
                </Typography>
                <input
                  type="date"
                  name="SendDate"
                  // disabled={
                  //   moment().subtract(2, "days").calendar() || isDisable
                  // }
                  onChange={handleChange}
                  value={data.SendDate}
                  id="date"
                  min={new Date()}
                  style={{
                    width: "95%",
                    height: "45px",
                    paddingRight: "15px",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    paddingLeft: "15px",
                    fontSize: "16px",
                    outline: "none",
                    display: isDisable ? "none" : "block",
                  }}
                />
                {errors.SendDate && (
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {errors.SendDate}
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "500", mt: 3, mb: 1.5 }}
                >
                  Select Time
                </Typography>
                <input
                  type="time"
                  name="SendTime"
                  disabled={isDisable}
                  onChange={handleChange}
                  value={data.SendTime}
                  id="time"
                  style={{
                    width: "95%",
                    height: "45px",
                    paddingRight: "15px",
                    borderRadius: "10px",
                    border: "none",
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    paddingLeft: "15px",
                    fontSize: "16px",
                    outline: "none",
                  }}
                />
                {errors.SendTime && (
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {errors.SendTime}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                mt: 10,
              }}
            >
              <Button
                type="submit"
                sx={{
                  width: "300px",
                  background: theme.palette.primary.Blend,
                  color: "#fff",
                  borderRadius: "10px",
                  height: "45px",
                  fontSize: "16px",
                  fontWeight: "600",
                  textTransform: "none",
                  "&:hover": {
                    background: theme.palette.primary.Blend,
                    opacity: 0.8,
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
        <hr />
        <Box sx={{ width: { xs: "100%", lg: "40%" }, p: 1 }}>
          <Typography
            variant="h6"
            sx={{
              // display: { xs: "block", md: "none" },
              fontSize: "28px",
              fontWeight: "500",
              position: { lg: "absolute" },
              top: { lg: "16px" },
            }}
          >
            Notifications History
          </Typography>
          {isLoading ? (
            <Loader />
          ) : (
            <Box
              sx={{
                width: "100%",
                overflow: "scroll",
                height: "calc(100vh - 120px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {notificationsList?.length > 0 ? (
                Object.entries(byDate).map(([date, x], i) => (
                  <>
                    <Box
                      sx={{
                        background: `linear-gradient(#7AACFF ,#3C75D4)`,
                        color: "#fff",
                        marginTop: { xs: "20px", lg: "0" },
                        fontWeight: "700",
                        fontSize: "16px",
                        textAlign: "center",
                        p: 1,
                        borderRadius: "8px",
                      }}
                      key={i}
                    >
                      {date}
                    </Box>
                    {x?.map((list, i) => {
                      return (
                        <Box
                          key={i}
                          sx={{
                            width: "100%",
                            background: "#CEE5FF40",
                            borderRadius: "10px",
                            display: "flex",
                            alignItems: "start",
                            p: 1,
                            my: 1.5,
                          }}
                        >
                          <Box>
                            <img src={notificationicon} alt="fsgsfgs" />
                          </Box>
                          <Box sx={{ width: "100%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                              }}
                            >
                              <Typography
                                sx={{
                                  pl: 1,
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {list?.title}
                              </Typography>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Typography
                                  sx={{
                                    pl: 1,
                                    fontSize: "12px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {moment(list?.created_at)
                                    .local()
                                    .format("hh:mm A")}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              sx={{ p: 1, fontSize: "14px", width: "70%" }}
                            >
                              {list?.description}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })}
                  </>
                ))
              ) : (
                <NoDataImg />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default NotificationScreen;
