import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Logo from "../../../Assets/image/dash/logo.svg";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import NoDataImg from "../../../Common/NoData";
import AccordionComponent from "./CategoryAccordian/CategoryAccordianComponent";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import { apiList } from "../../../Common/Api/ApiList";
import { toast } from "react-toastify";

export default function TrainerDetailsCategoriesTable({
  userId,
  categories,
  getData,
}) {
  const [expanded, setExpanded] = useState(false);
  // const tableContainerRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    // if (tableContainerRef.current)
    // {
    //   tableContainerRef.current.scrollTo({ top: 0, behavior: "instant" });
    // }
  };

  const [selectedCat, setSelectedCat] = useState([]);
  const [open, setOpen] = useState(false);

  const [isAllSelected, setIsAllSelected] = useState(false);
  const allIds = categories.flatMap((main) =>
    main.sub_cat.map((child) => child.id)
  );

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    // setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedItem(null);
  };

  const handleAction = async (status) => {
    handleClose();
    setOpen(false);
    let ActualStatus = status === -4 ? 1 : status;
    try
    {
      setLoading(true);
      setLoading(false);
      let formData = new FormData();
      formData.append("u_id", userId);
      formData.append("sub_cat_id", selectedCat);
      formData.append("status", ActualStatus);
      let res = await AxiosInstance.post(
        apiList.TrainerCategoryAction,
        formData
      );

      if (res?.data?.s)
      {
        toast.success(res?.data?.m);
        await getData();
      } else
      {
        toast.warn(res?.m);
      }
      setSelectedCat([]);
      setIsAllSelected(false);
      setLoading(false);
    } catch (error)
    {
      console.log(error);
    }
  };

  const handleChangeAll = () => {
    setSelectedCat((prevSelected) => {
      const isCurrentlyAllSelected = allIds.every((child) =>
        prevSelected.includes(child)
      );
      setIsAllSelected(!isCurrentlyAllSelected);
      return isCurrentlyAllSelected ? [] : allIds;
    });
  };

  const handleChangeAllButton = () => {
    setSelectedCat((prevSelected) => {
      const isCurrentlyAllSelected = allIds.every((child) =>
        prevSelected.includes(child)
      );
      setIsAllSelected(isCurrentlyAllSelected);
      return prevSelected;
    });
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        // maxHeight: "calc(100vh - 350px)",
        // overflowY: "scroll",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
      className="HideScrollBar"
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead
          sx={{
            ".MuiTableCell-root": {
              padding: "10px",
            },
            position: "sticky",
            top: "0",
            height: "50px",
            zIndex: 2,
            background:
              "linear-gradient(181.45deg, #7AACFF -80.86%, #3c75d5 98.77%)",
          }}
        >
          <TableRow>
            <TableCell sx={{ color: "white", width: "50px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>Category</Typography>
                <Box
                  sx={{
                    display: categories?.length > 0 ? "flex" : "none",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAllSelected}
                          onChange={handleChangeAll}
                          color="#FFF"
                        />
                      }
                      label={isAllSelected ? `Deselect All` : "Select All"}
                    />
                  </FormGroup>
                  <Box
                    sx={{
                      display: selectedCat?.length > 0 ? "flex" : "none",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={(e) => handleClick(e)}
                      sx={{
                        bgcolor: "#FFF",
                        textTransform: "none",
                        color: "#000",
                        fontSize: "14px",
                        width: "fit-content",
                        borderRadius: "10px",
                        "&:hover": { bgcolor: "#FFF" },
                      }}
                    >
                      Action
                      {anchorEl === null ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories?.length > 0 ? (
            categories?.map(
              (category, i) =>
                category?.status === 1 && (
                  <TableRow
                    key={i}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <AccordionComponent
                        data={category}
                        key={i + "ac"}
                        id={i + 1}
                        getData={getData}
                        handleChange={handleChange}
                        expanded={expanded}
                        userId={userId}
                        selectedCat={selectedCat}
                        setSelectedCat={setSelectedCat}
                        handleChangeAllButton={handleChangeAllButton}
                      />
                    </TableCell>
                  </TableRow>
                )
            )
          ) : (
            <TableRow>
              <TableCell colSpan={3}>
                <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
                  <NoDataImg />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => setOpen(1)}>Approve</MenuItem>
          <MenuItem onClick={() => setOpen(-3)}>Reject</MenuItem>
          {/* <MenuItem onClick={() => setOpen(-2)}>Restrict</MenuItem> */}
          {/* <MenuItem onClick={() => setOpen(-4)}>Unrestrict</MenuItem> */}
        </Menu>
      </Box>
      <ActionConfirmation
        open={open}
        handleClose={() => {
          setOpen(false);
          setAnchorEl(null);
        }}
        handleSubmit={() => handleAction(open)}
        loading={loading}
      />
    </TableContainer>
  );
}

export const ActionConfirmation = ({
  open,
  handleClose,
  loading,
  handleSubmit,
}) => {
  const theme = useTheme();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 330,
    bgcolor: "background.paper",
    borderRadius: "12px",
    //   border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  let TitleLabel =
    open === 1
      ? "Approve"
      : open === -3
        ? "Reject"
        : open === -2
          ? "Restrict"
          : open === -4 && "Unrestrict";
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
          }}
        >
          <img src={Logo} alt="Logo" srcSet="" />
        </Box>

        <Typography
          id="modal-modal-description"
          sx={{ mt: 1, textAlign: "center" }}
        >
          {`Are you sure to ${TitleLabel}?`}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            mt: 1,
            fontSize: "18px",
          }}
        >
          <Button
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={handleClose}
          >
            Cancle
          </Button>
          <Button
            variant="outlined"
            disabled={loading}
            sx={{
              textTransform: "none",
              background: theme.palette.primary.Blend,
              color: "#eee",
              minWidth: "100px",
            }}
            onClick={!loading && handleSubmit}
          >
            {loading ? <CircularProgress size={26} /> : "Confirm"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
