import { Box, MenuItem, Select, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import searchicon from "../../Assets/image/dash/Minimalistic Magnifer.png";
import viewicon from "../../Assets/image/user/223_frame.png";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../Common/Routes/Path";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import debounce from "lodash.debounce";
import { apiList } from "../../Common/Api/ApiList";
import TablePagination from "@mui/material/TablePagination";
import ErrorToaster from "../../Component/ErrorToaster";
import order from "../../Assets/image/dash/Record Circle.png";
import moment from "moment";
import ResponsiveDatePickers from "../BookingScreen/DateComponent";
import DateLogo from "../../Assets/image/dash/Calendar Search.png";
import { toast } from "react-toastify";
import ProfileImage from "../../Component/GetSignedImage";

const PAGE_SIZE_OPTIONS = [30];

const UserManagementScreen = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(2);
  const [ageSelected, setAgeSelected] = useState(2);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const StartDateMoment =
    startDate && moment(startDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  const EndDateMoment =
    endDate && moment(endDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

  const isStartDateFiltered =
    StartDateMoment && StartDateMoment.format("YYYY/MM/DD");
  const isEndDateFiltered = EndDateMoment && EndDateMoment.format("YYYY/MM/DD");

  const dateDifference =
    EndDateMoment && EndDateMoment.diff(StartDateMoment, "days");
  const fetchUserData = async (
    search = "",
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0],
    previousSearch = "",
    previousStatus = 2,
    age,
    prevAge,
    dateDifference,
    date
  ) => {
    try
    {
      setLoading(true);
      let newPage = page;

      if (
        search !== previousSearch ||
        status !== previousStatus ||
        age !== prevAge ||
        dateDifference !== date
      )
      {
        newPage = 0;
        setPage(0);
      }

      if (dateDifference !== null && dateDifference <= 0)
      {
        toast.error("End date must be later than the start date.");
        setLoading(false);
        setIsLoading(false);
        return;
      }

      let queryParams = new URLSearchParams({
        count: newPage * pageSize,
      });

      if (search.length > 0)
      {
        queryParams.append("search", search);
      }
      if (status !== 2)
      {
        queryParams.append("status", status);
      }
      if (age !== 2)
      {
        queryParams.append("agefilter", age);
      }
      if (dateDifference > 0)
      {
        queryParams.append("from_date", isStartDateFiltered);
        queryParams.append("to_date", isEndDateFiltered);
      }

      let api = `${apiList.UserList}?${queryParams.toString()}`;
      const response = await AxiosInstance.get(api);

      const Rowdata = response?.data?.r?.data?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));
      if (Rowdata?.length > 0)
      {
        setUser(Rowdata);
        setTotalData(response?.data?.r?.total_count);
      } else
      {
        if (newPage === 0)
        {
          setUser([]);
        }
        setTotalData(0);
      }

      setLoading(false);
      setIsLoading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setIsLoading(false);
      setLoading(false);
    }
  };

  const handleView = (userId) => {
    navigate(`${PathList.UserDetails}/${userId}`);
  };

  const fetchUserDataDebounced = debounce(
    (
      search,
      rowsPerPage,
      previousSearch,
      previousStatus,
      age,
      prevAge,
      dateDifference,
      date
    ) => {
      fetchUserData(
        search,
        page,
        rowsPerPage,
        previousSearch,
        previousStatus,
        age,
        prevAge,
        dateDifference,
        date
      );
    },
    300
  );

  const searchTermRef = useRef(searchTerm);
  const statusRef = useRef(status);
  const ageRef = useRef(ageSelected);
  const dateDifferenceRef = useRef(dateDifference);

  useEffect(() => {
    fetchUserDataDebounced(
      searchTerm,
      rowsPerPage,
      searchTermRef.current,
      statusRef.current,
      ageSelected,
      ageRef.current,
      dateDifference,
      dateDifferenceRef.current
    );

    searchTermRef.current = searchTerm;
    statusRef.current = status;
    ageRef.current = ageSelected;
    dateDifferenceRef.current = dateDifference;

    return () => {
      fetchUserDataDebounced.cancel();
    };
  }, [searchTerm, page, rowsPerPage, status, ageSelected, dateDifference]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    // setisPrevClick(page > newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr.",
      width: 90,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * PAGE_SIZE_OPTIONS;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "ID",
      width: 90,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "profile_img",
      headerName: "Profile",
      width: 90,
      headerAlign: "center",
      renderCell: (e) => (
        <ProfileImage imageUrl={e?.row?.profile_img} rowId={e.row.id} handleView={handleView} />
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "user_name",
      headerName: "Name",
      flex: 1,
      minWidth: 220,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "email",
      headerName: "Email",
      flex: 1,
      type: "string",
      minWidth: 300,
      headerAlign: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      field: "gender",
      headerName: "Gender",
      type: "string",
      width: 150,
      headerAlign: "center",
      renderCell: (e) =>
        e?.row?.gender === 1
          ? "Male"
          : e?.row?.gender === 2
            ? "Female"
            : e?.row?.gender === 3
              ? "Other"
              : "-",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "age_confirmation",
      headerName: "Age",
      width: 100,
      headerAlign: "center",
      renderCell: ({ row }) => (row?.age_confirmation === 0 ? "-" : "18+"),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "number",
      headerName: "Emergency No.",
      type: "string",
      flex: 1,
      minWidth: 230,
      headerAlign: "center",
      renderCell: (cell) => {
        let {
          row: { emr_first_name, emr_last_name, emr_number },
        } = cell;
        if (emr_first_name && emr_last_name && emr_number)
        {
          return (
            <span
              style={{
                background: "#3C75D4",
                color: "white",
                borderRadius: "5px",
                margin: "3px",
                padding: "5px 8px",
              }}
            >
              {emr_first_name} {emr_last_name} - {emr_number}
            </span>
          );
        } else
        {
          return "-";
        }
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "created_at",
      headerName: "Date",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => moment(e?.row?.created_at).format("ll"),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "status",
      headerName: "Status",
      type: "string",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => {
        const { status } = e.row;
        const statusStyle = {
          fontWeight: "500",
          opacity: "0.7",
          borderRadius: "10px",
          width: "100%",
          border: "1px solid",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };

        const boxStyle = {
          width: "100px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        };

        if (status === 1)
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "green",
                  borderColor: "green",
                }}
              >
                Active
              </span>
            </Box>
          );
        } else if (status === -1)
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "grey",
                  borderColor: "grey",
                }}
              >
                Restricted
              </span>
            </Box>
          );
        } else if (status === 0 || status === -2)
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "red",
                  borderColor: "red",
                }}
              >
                Deleted
              </span>
            </Box>
          );
        }
      },
    },
    {
      headerClassName: "super-app-theme--header",
      headerName: "View",
      sortable: false,
      width: 100,
      headerAlign: "center",
      renderCell: (e) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={viewicon}
            alt=""
            onClick={() => handleView(e.row.id)}
            style={{ cursor: "pointer" }}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "1rem",
          alignItems: { xs: "stretch", md: "end" },
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "350px" },
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              fontSize: "14px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              border: "none",
              outline: "none",
              paddingLeft: "20px",
            }}
          />
          <span
            style={{
              position: "absolute",
              right: "0",
              paddingRight: "15px",
              paddingTop: "5px",
            }}
          >
            <img src={searchicon} alt="Search" />
          </span>
        </Box>

        <Box
          sx={{
            display: "flex",
            // flexDirection: { xs: "column", md: "row" }, // Stack dropdowns on smaller screens
            justifyContent: "end",
            flexWrap: "wrap",
            gap: "1rem",
            width: "100%",
          }}
        >
          {/* Status Dropdown */}
          <Box
            sx={{
              padding: "8px",
              width: { xs: "100%", sm: "48%", md: "150px" },
              borderRadius: "8px",
              fontSize: "14px",
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "0.5rem",
                pb: "0.15rem",
                color: theme.palette.text.primary,
              }}
            >
              <img src={order} height={17} width={17} alt="" srcSet="" />
              Status
            </Box>

            <Select
              value={status}
              fullWidth
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                ".MuiSelect-select": {
                  padding: "8px 12px",
                },
              }}
              onChange={(e) => setStatus(e.target.value)}
            >
              {[
                { id: 2, status: "All" },
                { id: 1, status: "Active" },
                { id: -1, status: "Restricted" },
                { id: 0, status: "Deleted" },
              ].map((x, i) => (
                <MenuItem key={i} value={x.id} style={{ display: "block" }}>
                  {x?.status}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Age Dropdown */}
          <Box
            sx={{
              padding: "8px",
              width: { xs: "100%", sm: "48%", md: "150px" },
              borderRadius: "8px",
              fontSize: "14px",
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "0.5rem",
                pb: "0.15rem",
                color: theme.palette.text.primary,
              }}
            >
              <img src={order} height={17} width={17} alt="" srcSet="" />
              Age
            </Box>

            <Select
              value={ageSelected}
              fullWidth
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                ".MuiSelect-select": {
                  padding: "8px 12px",
                },
              }}
              onChange={(e) => setAgeSelected(e.target.value)}
            >
              {[
                { id: 2, status: "All" },
                { id: 1, status: "Above 18" },
                { id: 0, status: "Below 18" },
              ].map((x, i) => (
                <MenuItem key={i} value={x.id} style={{ display: "block" }}>
                  {x?.status}
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Date Picker */}
          <Box
            sx={{
              padding: "8px",
              width: { xs: "100%", sm: "100%", md: "300px" },
              borderRadius: "8px",
              fontSize: "14px",
              boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
                pb: "0.15rem",
                color: theme.palette.text.primary,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "0.5rem",
                }}
              >
                <img src={DateLogo} height={17} width={17} alt="" srcSet="" />
                Date
              </Box>
              <Box
                onClick={() => {
                  setStartDate(null);
                  setEndDate(null);
                }}
                sx={{
                  cursor: "pointer",
                  color: "#3498db",
                  display: dateDifference !== null ? "block" : "none",
                  "&:hover": { fontWeight: "500" },
                }}
              >
                Clear
              </Box>
            </Box>
            <ResponsiveDatePickers
              endDate={endDate}
              setEndDate={setEndDate}
              startDate={startDate}
              setStartDate={setStartDate}
            />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 200px)",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "5px",
          mt: 3,
          // mt: 5,
          p: 3,
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
          User List
        </Typography>
        {/* DataGrid with filtered data */}
        {/* <Box sx={{ height: "100% !important" }}> */}
        {isLoading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              height: "calc(100% - 50px)",
            }}
          >
            <DataGrid
              pagination
              loading={loading}
              rowCount={totalData}
              rows={user}
              disableColumnMenu={true}
              disableAutosize={true}
              paginationMode="server"
              hideFooter
              disableColumnFilter={true}
              getRowId={(e) => e?.rowid}
              slots={{ noRowsOverlay: NoDataImg }}
              columns={columns}
              onRowClick={(e) =>
                navigate(`${PathList.UserDetails}/${e?.row?.id}`)
              }
              sx={{
                cursor: "pointer",
                display: "flex",
                "& .MuiDataGrid-cell": {
                  // border: "0.1px solid #DDDDDD50",
                  "&:focus": {
                    outline: "none",
                  },
                },
                justifyContent: "center",
                ".MuiDataGrid-cell": { textAlign: "center" },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  color: "white",
                },
                "& .super-app-theme--header": {
                  background: theme.palette.primary.Blend,
                },
              }}
              pageSize={rowsPerPage}
            />
            <TablePagination
              component="div"
              count={totalData}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default UserManagementScreen;

