import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useTheme } from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../Common/Api/ApiHelper";
import { apiList } from "../Common/Api/ApiList";
import { toast } from "react-toastify";
import ErrorToaster from "./ErrorToaster";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

const validationSchema = Yup.object({
    Title: Yup.string().required("Title is required"),
    Description: Yup.string().required("Description is required"),
});

export default function IndividualNotificationComponent({ userId, type }) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const theme = useTheme();

    const formik = useFormik({
        initialValues: {
            Title: "",
            Description: "",
        },
        validationSchema,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            let f = new FormData();
            f.append("title", values.Title);
            f.append("msg", values.Description);
            f.append("u_id", userId);
            f.append("type", type);
            f.append("type_id", 0);
            try
            {
                setSubmitting(true);
                const response = await AxiosInstance.post(
                    apiList.sendPersonalNotification,
                    f
                );
                if (response?.data?.s === 1)
                {
                    toast.success("Notification sent successfully");
                } else
                {
                    toast.error(response?.data?.m);
                }
                setSubmitting(false);
            } catch (error)
            {
                <ErrorToaster error={error} />;
                setSubmitting(false);
            }
            resetForm();
            handleClose();
        },
    });

    return (
        <div>
            <Button
                onClick={handleOpen}
                sx={{
                    background: theme.palette.primary.Blend,
                    textTransform: "none",
                    color: "#FFF",
                    fontSize: "14px",
                    width: "150px",
                    height: "40px",
                    mx: { md: 5 },
                    "&:hover": { bgcolor: "#F2F2F2" },
                }}
            >
                Send Notification
            </Button>
            <Modal
                open={open}
                onClose={() => {
                    handleClose();
                    formik?.resetForm();
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{ color: theme.palette.primary.paper, fontWeight: 500 }}
                    >
                        Send Personal Notification
                    </Typography>
                    <form
                        style={{ width: "100%", marginTop: 2 }}
                        onSubmit={formik.handleSubmit}
                    >
                        <Typography
                            sx={{ fontSize: "16px", fontWeight: "500", mb: 0.5, mt: 1 }}
                        >
                            Notification Title
                        </Typography>
                        <input
                            type="text"
                            name="Title"
                            value={formik.values.Title}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Eg :- Gym Time Now"
                            style={{
                                width: "100%",
                                height: "45px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                paddingLeft: "15px",
                                fontSize: "16px",
                                outline: "none",
                            }}
                        />
                        {formik.touched.Title && formik.errors.Title && (
                            <Typography sx={{ color: "red", fontSize: "14px" }}>
                                {formik.errors.Title}
                            </Typography>
                        )}

                        <Typography
                            sx={{ fontSize: "16px", fontWeight: "500", mt: 2, mb: 0.5 }}
                        >
                            Description
                        </Typography>
                        <textarea
                            name="Description"
                            value={formik.values.Description}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Description"
                            style={{
                                width: "100%",
                                minHeight: "150px",
                                paddingTop: "15px",
                                borderRadius: "10px",
                                border: "none",
                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                paddingLeft: "15px",
                                fontSize: "16px",
                                outline: "none",
                            }}
                        />
                        {formik.touched.Description && formik.errors.Description && (
                            <Typography sx={{ color: "red", fontSize: "14px" }}>
                                {formik.errors.Description}
                            </Typography>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                mt: 5,
                            }}
                        >
                            <Button
                                type="submit"
                                disabled={formik.isSubmitting}
                                sx={{
                                    width: "300px",
                                    background: theme.palette.primary.Blend,
                                    color: "#fff",
                                    borderRadius: "10px",
                                    height: "45px",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    textTransform: "none",
                                    "&:hover": {
                                        background: theme.palette.primary.Blend,
                                        opacity: 0.8,
                                    },
                                }}
                            >
                                {formik.isSubmitting ? "Loading..." : "Submit"}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}
