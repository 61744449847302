import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { TablePagination, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import moment from "moment";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import { debounce } from "lodash";
import { toast } from "react-toastify";

const PAGE_SIZE_OPTIONS = [30];

export default function PaymentList(props) {
  const { paymentFilter, endDate, startDate } = props;
  const [totalData, setTotalData] = useState(0);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);

  const StartDateMoment =
    startDate && moment(startDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  const EndDateMoment =
    endDate && moment(endDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

  const isStartDateFiltered =
    StartDateMoment && StartDateMoment.format("YYYY/MM/DD");
  const isEndDateFiltered = EndDateMoment && EndDateMoment.format("YYYY/MM/DD");

  const dateDifference =
    EndDateMoment && EndDateMoment.diff(StartDateMoment, "days");

  const fetchData = async (
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0],
    previousPaymentFilter = "",
    previousDateDiffernce = 0
  ) => {
    try {
      setIsLoading(true);
      setLoading(true);
      let newPage = page;
      if (
        paymentFilter !== previousPaymentFilter ||
        dateDifference !== previousDateDiffernce
      ) {
        newPage = 0;
        setPage(0);
      }

      if (dateDifference !== null && dateDifference <= 0) {
        toast.error("End date must be later than the start date.");
        setLoading(false);
        setIsLoading(false);
        return;
      }
      const offset = newPage * pageSize;
      let queryParams = new URLSearchParams({
        count: offset,
        limit: pageSize,
      });
      if (!(paymentFilter === "All")) {
        queryParams.append("status", paymentFilter);
      }
      if (dateDifference && dateDifference !== 0) {
        queryParams.append("from_date", isStartDateFiltered);
        queryParams.append("to_date", isEndDateFiltered);
      }
      const api = `${apiList.GetPaymentList}?${queryParams.toString()}`;
      const res = await AxiosInstance.get(api);
      const Rowdata = res?.data?.r?.map((data, i) => ({
        rowid: i + 1 + page * rowsPerPage,
        ...data,
      }));
      if (res?.data?.s === 1) {
        setData(Rowdata);
        setTotalData(res?.data?.c);
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  const paymentFilterRef = useRef(paymentFilter);
  const dateDifferenceRef = useRef(dateDifference);
  const debouncedFetchData = debounce(() => {
    fetchData(
      page,
      rowsPerPage,
      paymentFilterRef.current,
      dateDifferenceRef.current
    );
    paymentFilterRef.current = paymentFilter;
    dateDifferenceRef.current = dateDifference;
  }, 400);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [page, rowsPerPage, paymentFilter, dateDifference]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr.",
      width: 80,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 220,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <span>{`${cell?.row?.first_name} ${cell?.row?.last_name}`}</span>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "txn_payment_id",
      headerName: "Transaction Id",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "payment_method",
      headerName: "Payment Method",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "created_at",
      headerName: "Time",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <span>{`${moment
            .utc(cell?.row?.created_at)
            .local()
            .format("LT")}`}</span>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <span>{`${moment
            .utc(cell?.row?.created_at)
            .local()
            .format("ll")}`}</span>
        );
      },
    },

    {
      headerClassName: "super-app-theme--header",
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: ({ row }) => {
        const orderTypeMap = {
          1: "Auto Cancel Session",
          2: "Session Cancelled by User or Trainer",
          3: "Training Plan Auto Cancel",
          4: "Training Plan Manual Cancel",
        };

        return orderTypeMap[row?.order_type] || "NA";
      },
    },

    {
      headerClassName: "super-app-theme--header",
      field: "cat_name",
      headerName: "Category",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
    },

    {
      headerClassName: "super-app-theme--header",
      field: "type",
      headerName: "Session Type",
      width: 150,
      headerAlign: "center",
      renderCell: (cell) => {
        if (cell?.row?.sub_type === 1) {
          return <span style={{ color: "#C37500" }}>Virtual</span>;
        } else {
          return <span style={{ color: "#1451B6" }}>Onsite</span>;
        }
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "total_amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (cell) => `$${cell?.row?.total_amount}`,
    },
    {
      headerClassName: "super-app-theme--header",
      field: "is_payment",
      headerName: "Payment Status",
      type: "string",
      flex: 1,
      minWidth: 200,
      headerAlign: "center",
      renderCell: (cell) => {
        return (
          <span
            style={{
              color:
                cell.row.is_payment === 1
                  ? "green"
                  : cell.row.is_payment === 0
                  ? "red"
                  : cell.row.is_payment === -1 && "orange",
            }}
          >
            {cell.row.is_payment === 1
              ? "Paid"
              : cell.row.is_payment === 0
              ? "Cancelled"
              : cell.row.is_payment === -1 && "Refund"}
          </span>
        );
      },
    },
  ];
  return (
    <Box sx={{ width: "100%", fontSize: "14px" }}>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 210px)",
          minHeight: "500px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "5px",
          p: 3,
        }}
      >
        <Box sx={{ height: `calc(100% - 50px)` }}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                Payment List
              </Typography>
              <DataGrid
                rows={data}
                pagination
                loading={loading}
                hideFooter
                disableColumnMenu={true}
                disableAutosize={true}
                slots={{ noRowsOverlay: NoDataImg }}
                disableColumnFilter={true}
                columns={columns}
                slotProps={{
                  loadingOverlay: {
                    variant: "skeleton",
                    noRowsVariant: "skeleton",
                  },
                }}
                hideFooterSelectedRowCount={true}
                getRowId={(e) => e?.rowid}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  ".MuiDataGrid-cell": {
                    textAlign: "center",
                    "&:focus": { outline: "none" },
                  },
                  ".MuiDataGrid-cell:focus": { outline: "none" },
                  ".MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "600",
                    color: "white",
                  },
                  "& .super-app-theme--header": {
                    background: theme.palette.primary.Blend,
                  },
                }}
                pageSize={rowsPerPage}
              />

              <TablePagination
                component="div"
                count={totalData}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
