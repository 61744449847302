import { useState, useEffect } from "react";
import dummyimage from "../Assets/image/user/9720009.jpg";
import FetchSignedUrl from "./URL_Generator";

const ProfileImage = ({ imageUrl, rowId, handleView }) => {
  const [signedUrl, setSignedUrl] = useState(null);

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (imageUrl) {
        const path = imageUrl.split(
          "https://instatrainme-api.s3.amazonaws.com/"
        )[1];
        const signed = await FetchSignedUrl({ path });
        setSignedUrl(signed);
      }
    };

    fetchImageUrl();
  }, [imageUrl]);

  return (
    <img
      src={signedUrl || dummyimage}
      alt="Profile"
      onClick={() => handleView(rowId)}
      style={{
        height: "40px",
        width: "50px",
        borderRadius: "10px",
        border: "1px solid #E0E0E0",
        objectFit: "cover",
        cursor: "pointer",
      }}
    />
  );
};

export default ProfileImage;
