import { Box, Typography } from "@mui/material";
import NoDataImg from "../../../Common/NoData";
import { useState } from "react";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import { apiList } from "../../../Common/Api/ApiList";

export default function Earning({ id }) {
  const [data, setdata] = useState([]);
  const fetchData = async (count = 0) => {
    try
    {
      const res = await AxiosInstance.get(
        `${apiList.TrainersDetails2}?t_id=${id}&type=1&count=${count}`
      );
    } catch (error) { }
  };
  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        maxHeight: { md: "calc(100vh - 350px)" },
        overflow: { md: "scroll" },
        // minHeight: '350px',

        borderRadius: "8px",
      }}
    >
      <Typography
        sx={{
          p: "1rem",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          color: "white",
          height: "50px",
          position: "sticky",
          top: "0",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
      >
        Earning
      </Typography>
      <Box sx={{ p: 2, height: "calc(100% - 50px)", overflow: "scroll" }}>
        {data?.earning?.length > 0 ? (
          <Box
            sx={{
              p: 2,
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              width: "250px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100% - 50px)",
            }}
          >
            {data?.earning?.map((licid, i) => {
              return null;
            })}
          </Box>
        ) : (
          <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
            <NoDataImg />
          </Box>
        )}
      </Box>
    </Box>
  );
}
