import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDaPk6_LXaksf73sIzyTye9HHNAMUz63LA",
    authDomain: "instatrainme-426404.firebaseapp.com",
    projectId: "instatrainme-426404",
    storageBucket: "instatrainme-426404.appspot.com",
    messagingSenderId: "620525581169",
    appId: "1:620525581169:web:bf5bef5817bfee33a3bcd0",
    measurementId: "G-CBRZ6HL7CD",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
    const title = payload?.notification?.title || "New Notification";
    const options = {
        body: payload?.notification?.body || "You have a new request!",
        icon: "/favicon.ico",
    };
    if (Notification.permission === "granted")
    {
        new Notification(title, options);
    } 
});
