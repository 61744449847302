import { BrowserRouter } from "react-router-dom";
import "./App.css";
import StateStoreProvider from "./GlobalContext/StateStore";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Common/Theme/theme";
import RoutesList from "./Common/Routes/Routes";
import { AWSProvider } from "./GlobalContext/AWSContext";
import { useEffect } from "react";
import addUpdateFCM from "./Firebase/addUpadateFCM";

function App() {
  const requestPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        if ("serviceWorker" in navigator) {
          const registration = await navigator.serviceWorker.register(
            "/firebase-messaging-sw.js"
          );
          await addUpdateFCM(registration);
        }
      } else {
        console.error("Notification permission denied");
      }
    } catch (error) {
      console.error("Error getting notification permission:", error);
    }
  };

  useEffect(() => {
    navigator.serviceWorker.ready.then(async (registration) => {
      const subscription = await registration.pushManager.getSubscription();
      if (!subscription) {
        await addUpdateFCM();
      }
    });
    requestPermission();
  }, []);

  return (
    <StateStoreProvider>
      <ThemeProvider theme={theme("light")}>
        <BrowserRouter>
          <AWSProvider>
            {/* All screens routes file is here...*/}
            <RoutesList />
          </AWSProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StateStoreProvider>
  );
}

export default App;
