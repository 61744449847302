import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NoDataImg from "../../../Common/NoData";
import moment from "moment";
import SlotDrop from "./SlotDrop";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import { apiList } from "../../../Common/Api/ApiList";
import { useParams } from "react-router-dom";
import Loader from "../../../Common/Loader";

export default function SlotDetails() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);
  // const [isDataAvailable, setIsDataAvailable] = useState(false);

  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        maxHeight: { md: "calc(100vh - 350px)" },
        overflow: { md: "scroll" },
        // minHeight: '350px',
        borderRadius: "8px",
        position: "relative",
        // mb: "1rem",
      }}
      className="HideScrollBar"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          pr: "1rem",
          position: "sticky",
          top: "0",
          borderTopRightRadius: "11px",
          borderTopLeftRadius: "11px",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
        }}
      >
        <Typography
          sx={{
            p: "1rem",
            height: "50px",
            // background:
            //   "linear-gradient(181.45deg, #7AACFF -80.86%, #3c75d5 98.77%)",
            color: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          Slot Details
        </Typography>

        {activeTab === 1 && (
          <SlotDrop
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            setSelectedMonth={setSelectedMonth}
            setSelectedYear={setSelectedYear}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
          height: "calc(100% - 50px)",
          overflowY: "scroll",
        }}
        className="HideScrollBar"
      >
        {
          <>
            <Box sx={{ display: "flex", gap: 2 }}>
              {[
                { id: 0, tab: "Weekly Schedule" },
                { id: 1, tab: "Unavailability" },
              ].map((tab, i) => {
                const isActive = activeTab === tab?.id;
                return (
                  <Box key={i} onClick={() => setActiveTab(tab?.id)}>
                    <span
                      style={{
                        cursor: "pointer",
                        fontWeight: isActive ? 500 : 400,
                        color: isActive ? "#000" : "#00000090",
                        borderBottom: isActive && "2px solid #3c75d5",
                      }}
                    >
                      {tab?.tab}
                    </span>
                  </Box>
                );
              })}
            </Box>
            <Box>
              {activeTab === 0 ? (
                <WeeklySchedule id={id} />
              ) : (
                <Unavailability
                  id={id}
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                // setIsDataAvailable={setIsDataAvailable}
                />
              )}
            </Box>
          </>
        }
      </Box>
    </Box>
  );
}

export const WeeklySchedule = ({ id }) => {
  const [slotsDetails, setSlotsDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async (id) => {
    try
    {
      setLoading(true);
      let params = new URLSearchParams({
        t_id: id,
      });

      const response = await AxiosInstance.get(
        `${apiList.TrainersWeeklyScheduleDetails}?${params.toString()}`
      );
      let x = groupSlots(response?.data?.s === 1 ? response?.data?.r : []);
      setSlotsDetails(x);
      setLoading(false);
    } catch (error)
    {
      console.log(error);
    }
  };

  function groupSlots(data) {
    const groupedData = {};

    const days = [
      '',
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    data.forEach((item) => {
      let key = item.day ? days[item.day] : null;

      if (!key) return;

      if (!groupedData[key])
      {
        groupedData[key] = {
          group_key: key,
          slots: [],
        };
      }

      groupedData[key].slots.push({
        slot_id: item.slot_id,
        frm_time: item.frm_time,
        to_time: item.to_time,
      });
    });

    return Object.values(groupedData);
  }

  useEffect(() => {
    getData(id);
  }, [id]);

  return (
    <>
      {loading ? (
        <Box sx={{ height: "350px" }}>
          <Loader />
        </Box>
      ) : slotsDetails?.length > 0 ? (
        slotsDetails?.map((date, i) => (
          <Box
            key={date + '-' + i}
            sx={{
              p: 2,
              display: "flex",
              gap: "1rem",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "start",
              borderBottom: "1px solid #7AACFF9C",
              my: 1.5,
            }}
          >
            <Box sx={{ borderRight: "1px solid grey", width: "200px" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  // background: "#4949491c",
                  padding: "0 0.5rem",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                {date?.group_key}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
                // overflowX: "scroll",
                flexWrap: "wrap",
              }}
            >
              {date?.slots.map((detail, i) => (
                <Box
                  key={i}
                  sx={{
                    background: "#CEE5FF",
                    borderRadius: "5px",
                    width: "200px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      width: "200px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {moment(detail.frm_time, "hh:mm:ss").format("hh:mm A")} -{" "}
                    {moment(detail.to_time, "hh:mm:ss").format("hh:mm A")}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))
      ) : (
        <Box sx={{ padding: "1rem", width: "100%", height: "100%" }}>
          <NoDataImg />
        </Box>
      )}
    </>
  );
};

export const Unavailability = ({ id, selectedMonth, selectedYear }) => {
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async (id, m, y) => {
    try
    {
      setLoading(true);
      let params = new URLSearchParams({
        id: id,
      });
      if (m !== 0)
      {
        params.append("month", m);
      }
      if (y !== 0)
      {
        params.append("year", y);
      }

      const response = await AxiosInstance.get(
        `${apiList.TrainersDetails_slots}?${params.toString()}`
      );
      if (response?.data?.s)
      {
        const x = groupSlots(response?.data?.r);
        setSlots(x);
      } else
      {
        setSlots([]);
      }
      setLoading(false);
    } catch (error)
    {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(id, selectedMonth, selectedYear);
  }, [selectedMonth, selectedYear, id]);

  function groupSlots(data) {
    const groupedData = {};

    data.forEach((item) => {
      let key = item.date ? moment(item.date).format("ll") : null;

      if (!key) return;

      if (!groupedData[key])
      {
        groupedData[key] = {
          group_key: key,
          slots: [],
          day: moment(item.date).format("dddd"),
        };
      }

      groupedData[key].slots.push({
        slot_id: item.slot_id,
        frm_time: item.frm_time,
        to_time: item.to_time,
      });
    });

    return Object.values(groupedData);
  }

  return (
    <>
      {loading ? (
        <Box sx={{ height: "350px" }}>
          <Loader />
        </Box>
      ) : slots?.length > 0 ? (
        slots.map((date,i) => (
          <Box
            key={i}
            sx={{
              p: 2,
              display: "flex",
              gap: "1rem",
              borderRadius: "8px",
              alignItems: "center",
              justifyContent: "start",
              borderBottom: "1px solid #7AACFF9C",
              my: 1.5,
            }}
          >
            {/* Date & Day Box */}
            <Box sx={{ borderRight: "1px solid grey", width: "200px" }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  padding: "0 0.5rem",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                {date.group_key}
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "center",
                  color: "#00000090",
                }}
              >
                ({date.day})
              </Typography>
            </Box>

            {/* Slots */}
            <Box
              sx={{
                display: "flex",
                gap: 1,
                alignItems: "center",
                justifyContent: "start",
                width: "100%",
                flexWrap: "wrap",
                // overflowX: "scroll",
              }}
            >
              {date.slots.map((detail, i) => (
                <Box
                  key={i}
                  sx={{
                    background: "#FACCCC",
                    borderRadius: "5px",
                    width: "200px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      width: "200px",
                      textAlign: "center",
                      padding: "10px",
                    }}
                  >
                    {moment(detail.frm_time, "hh:mm:ss").format("hh:mm A")} -{" "}
                    {moment(detail.to_time, "hh:mm:ss").format("hh:mm A")}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        ))
      ) : (
        <Box sx={{ padding: "1rem", width: "100%", height: "350px" }}>
          <NoDataImg />
        </Box>
      )}
    </>
  );
};
