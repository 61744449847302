import { Box, MenuItem, Select, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import userTypeLogo from "../../Assets/image/dash/Banknote.png";
import order from "../../Assets/image/dash/Record Circle.png";
import ResponsiveDatePickers from "./DateComponent";
import DateLogo from "../../Assets/image/dash/Calendar Search.png";
import moment from "moment";

export default function DropItemsComponent(props) {
  const {
    setOrderStatusFilter,
    orderStatusFilter,
    paymentFilter,
    setPaymentFilter,
    endDate,
    setEndDate,
    startDate,
    setStartDate,
  } = props;

  const theme = useTheme();

  const StartDateMoment =
    startDate && moment(startDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  const EndDateMoment =
    endDate && moment(endDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

  const dateDifference =
    EndDateMoment && EndDateMoment.diff(StartDateMoment, "days");

  useEffect(() => { }, [dateDifference, startDate, EndDateMoment]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: { sm: "2rem", lg: "1rem" },
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            padding: "8px",
            width: {
              xs: "100%",
              sm: "200px",
              md: "160px",
              lg: "180px",
              xl: "200px",
            },
            borderRadius: "8px",
            fontSize: "14px",
            boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "0.5rem",
              pb: "0.15rem",
              color: theme.palette.text.primary,
            }}
          >
            <img src={userTypeLogo} height={17} width={17} alt="" srcSet="" />
            Payment
          </Box>

          <Select
            value={paymentFilter}
            fullWidth
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              ".MuiSelect-select": {
                padding: "8px 12px",
              },
            }}
            onChange={(e) => {
              const {
                target: { value },
              } = e;

              setPaymentFilter(value);
            }}
          >
            {[
              {
                id: "All",
                status: "All",
              },
              {
                id: "1",
                status: "Paid",
              },

              {
                id: "-1",
                status: "Refund",
              },
              {
                id: "2",
                status: "Unpaid",
              },
            ].map((x, i) => {
              return (
                <MenuItem key={i} value={x?.id} sx={{ padding: "7px" }}>
                  {x?.status}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <Box
          sx={{
            padding: "8px",
            width: {
              xs: "100%",
              sm: "200px",
              md: "160px",
              lg: "180px",
              xl: "200px",
            },
            borderRadius: "8px",
            fontSize: "14px",
            boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "0.5rem",
              pb: "0.15rem",
              color: theme.palette.text.primary,
            }}
          >
            <img src={order} height={17} width={17} alt="" srcSet="" />
            Status
          </Box>
          <Select
            value={orderStatusFilter}
            fullWidth
            displayEmpty
            disabled={paymentFilter == 2}
            sx={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              ".MuiSelect-select": {
                padding: "8px 12px",
              },
            }}
            onChange={(e) => setOrderStatusFilter(e.target.value)}
          >
            {[
              "All",
              "Pending",
              "Confirmed",
              "On the way",
              "Arrived",
              "Start session",
              "Completed",
              "Cancelled",
            ].map((x, i) => {
              return (
                <MenuItem key={i} value={i} sx={{ padding: "7px" }}>
                  {x}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>

      <Box
        sx={{
          padding: "8px",
          width: { xs: "100%", sm: "300px" },
          borderRadius: "8px",
          fontSize: "14px",
          boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",

            gap: "0.5rem",
            pb: "0.15rem",
            color: theme.palette.text.primary,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "0.5rem",
            }}
          >
            <img src={DateLogo} height={17} width={17} alt="" srcSet="" />
            Date
          </Box>
          <Box
            onClick={() => {
              setStartDate(null);
              setEndDate(null);
            }}
            sx={{
              cursor: "pointer",
              color: "#3498db",
              display: dateDifference !== null ? "block" : "none",
              "&:hover": { fontWeight: "500" },
            }}
          >
            Clear
          </Box>
        </Box>
        <ResponsiveDatePickers
          endDate={endDate}
          setEndDate={setEndDate}
          startDate={startDate}
          setStartDate={setStartDate}
        />
      </Box>
    </>
  );
}
