import { Box, TablePagination, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Loader from "../../../Common/Loader";
import { DataGrid } from "@mui/x-data-grid";
import NoDataImg from "../../../Common/NoData";
import { PathList } from "../../../Common/Routes/Path";
import viewicon from "../../../Assets/image/user/223_frame.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DraftsIcon from "@mui/icons-material/Drafts";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import { apiList } from "../../../Common/Api/ApiList";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import ErrorToaster from "../../../Component/ErrorToaster";
const PAGE_SIZE_OPTIONS = [30];
const PaymnetTab = ({ type }) => {
    const [loading, setloading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [totalData, setTotalData] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
    const theme = useTheme();
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    const userID = userData?.id;
    const handlePageChange = (e, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const fetchData = async (
        id,
        page = 0,
        pageSize = PAGE_SIZE_OPTIONS[0],
        type
        // search = "",
        // status,
        // previousSearch = "",
        // previousStatus = 2,
        // type = [],
        // prevType = [],
        // ratingRange = "All",
        // prevRatingRange = "All",
        // dateDifference,
        // date,
        // ageSelected,
        // prevAge
    ) => {
        try
        {
            setloading(true);
            let newPage = page;
            // if (
            //     search !== previousSearch ||
            //     status !== previousStatus ||
            //     type !== prevType ||
            //     ratingRange !== prevRatingRange ||
            //     dateDifference !== date ||
            //     ageSelected !== prevAge
            // )
            // {
            //     newPage = 0;
            //     setPage(0);
            // }

            // if (dateDifference !== null && dateDifference <= 0)
            // {
            //     toast.error("End date must be later than the start date.");
            //     setloading(false);
            //     setIsLoading(false);
            //     return;
            // }
            let queryParams = new URLSearchParams({
                count: newPage * pageSize,
                u_id: id,
            });

            if (type > 0)
            {
                queryParams.append("type", type);
            }

            // if (search.length > 0)
            // {
            //     queryParams.append("search", search);
            // }
            // if (status !== 2)
            // {
            //     queryParams.append("status", status);
            // }
            // if (ageSelected !== 2)
            // {
            //     queryParams.append("agefilter", ageSelected);
            // }
            // if (dateDifference && dateDifference > 0)
            // {
            //     queryParams.append("from_date", isStartDateFiltered);
            //     queryParams.append("to_date", isEndDateFiltered);
            // }
            // if (!type.includes("all"))
            // {
            //     type.map((x) => queryParams.append(x, 1));
            // }

            // if (!(ratingRange === "All"))
            // {
            //     queryParams.append("rat_filter", ratingRange);
            // }
            const api = `${apiList.getAllNewRequest}?${queryParams.toString()}`;
            const response = await AxiosInstance.get(api);
            const Rowdata = response?.data?.r?.map((data, i) => ({
                rowid: i + 1 + page * rowsPerPage,
                ...data,
            }));

            if (Rowdata?.length > 0)
            {
                setData(Rowdata);
                setTotalData(response?.data?.c ?? 0);
            } else
            {
                if (newPage === 0)
                {
                    setData([]);
                }
                setTotalData(0);
            }
            setIsLoading(false);
            setloading(false);
        } catch (error)
        {
            <ErrorToaster error={error} />;
            setloading(false);
            setIsLoading(false);
        }
    };

    const columns = [
        {
            headerClassName: "super-app-theme--header",
            field: "rowid",
            headerName: "Sr.",
            width: 90,
            headerAlign: "center",
            renderCell: (params) => {
                return params.row.rowid + page * PAGE_SIZE_OPTIONS;
            },
        },

        {
            headerClassName: "super-app-theme--header",
            field: "title",
            headerName: "Title",
            flex: 1,
            minWidth: 220,
            headerAlign: "center",
        },
        {
            headerClassName: "super-app-theme--header",
            field: "description",
            headerName: "Description",
            type: "number",
            flex: 1,
            minWidth: 300,
            headerAlign: "center",
        },

        {
            headerClassName: "super-app-theme--header",
            field: "created_at",
            headerName: "Date",
            width: 200,
            headerAlign: "center",
            renderCell: (e) => moment(e?.row?.created_at).format("lll"),
        },
    ];

    useEffect(() => {
        fetchData(userID, page, rowsPerPage, type);
    }, [userID, page, type]);
    return (
        <Box
            sx={{
                width: "100%",
                // minHeight: "550px",
                height: "calc(100vh - 160px)",
                // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                borderRadius: "5px",
                // mt: 3,
                // p: 3,
            }}
        >
            {/* DataGrid with filtered data */}
            {/* <Box sx={{ height: "500px" }}> */}
            {isLoading ? (
                <Loader />
            ) : (
                <Box
                    sx={{
                        height: "calc(100% - 50px)",
                    }}
                >
                    <DataGrid
                        rows={data}
                        rowCount={-1}
                        pagination
                        paginationMode="server"
                        disableColumnMenu={true}
                        // disableColumnSorting={true}
                        disableAutosize={true}
                        disableColumnFilter={true}
                        disableRowSelectionOnClick={true}
                        getRowId={(e) => e?.rowid}
                        hideFooter
                        loading={loading}
                        slots={{ noRowsOverlay: NoDataImg }}
                        columns={columns}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            ".MuiDataGrid-cell": {
                                textAlign: "center",
                                "&:focus": {
                                    outline: "none",
                                },
                            },
                            ".MuiDataGrid-columnHeaderTitle": {
                                fontWeight: "600",
                                color: "white",
                            },
                            "& .super-app-theme--header": {
                                background: theme.palette.primary.Blend,
                            },
                        }}
                        pageSizeOptions={[10]}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                    <TablePagination
                        component="div"
                        count={totalData}
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        rowsPerPageOptions={PAGE_SIZE_OPTIONS}
                    />
                </Box>
            )}
            {/* </Box> */}
        </Box>
    );
};

export default PaymnetTab;
