import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
// import ListModal from "./UserModal";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import viewIcon from "../../Assets/image/dash/main/view.png";
import blockIcon from "../../Assets/image/dash/main/block.png";
import verifyIcon from "../../Assets/image/dash/main/verify.png";
// import defaultImg from "../../Assets/image/dash/main/np.png";
import dummyimage from "../../Assets/image/user/9720009.jpg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../Common/Routes/Path";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { toast } from "react-toastify";
import ErrorToaster from "../../Component/ErrorToaster";
import FetchSignedUrl from "../../Component/URL_Generator";
import ProfileImageCell from "../../Component/ProfileImageCell";

export default function NewTrainerListComponent({
  fetchData,
  data,
  isloading,
}) {
  const navigate = useNavigate();
  // const handleAction = async (id, status) => {
  //   try
  //   {
  // const res = await AxiosInstance.post(`${apiList.UserApproval}`, {
  //       id: id,
  //       status: status,
  //     });
  //     if (res?.data?.s === 1)
  //     {
  //       toast.success(res?.data?.m);
  //       fetchData();
  //     } else
  //     {
  //       toast.error(res?.data?.m);
  //     }
  //   } catch (error)
  //   {
  //     <ErrorToaster error={error} />;
  //   }
  // };

  const handleView = (id) => {
    navigate(`${PathList.TrainerDetails}/${id}`);
  };

  const columns = [
    {
      field: "rowid",
      headerName: "Sr.",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "id",
      headerName: "ID",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profile_img",
      headerName: "Profile",
      minWidth: 60,
      flex: 0.5,
      renderCell: (cell) => (
        <ProfileImageCell profileUrl={cell?.row?.profile_img} />
      ),
      align: "center",
      headerAlign: "center",
    },

    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 220,
      align: "center",
      headerAlign: "center",
    },
    {
      headerName: "Action",
      sortable: false,
      width: 80,
      headerAlign: "center",

      renderCell: (cell) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img
              src={viewIcon}
              height={25}
              onClick={() => handleView(cell?.row?.id)}
              alt=""
              style={{ cursor: "pointer" }}
            />
            {/* <img
              src={verifyIcon}
              height={25}
              onClick={() => handleAction(cell?.row?.id, 1)}
              alt=""
              style={{ cursor: "pointer" }}
            />
            <img
              src={blockIcon}
              height={25}
              onClick={() => handleAction(cell?.row?.id, -1)}
              alt=""
              style={{ cursor: "pointer" }}
            /> */}
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ width: "100%", height: `calc(100vh - 230px)` }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "600",
          padding: "0.5rem 0",
          boxSizing: "border-box",
        }}
      >
        <Box> New Trainers</Box>
        <Box>
          {/* <ListModal data={data} item={"Trainers"} /> */}
          <Button
            sx={{
              color: "black",
              fontWeight: "600",
              fontFamily: "Helvetica",
              textTransform: "none",
              padding: "0",
              fontSize: "16px",
            }}
            disabled={data?.length === 0 ? true : false}
            onClick={() => navigate(PathList.Trainer)}
          >
            View All
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: `calc(100vh - 270px)` }}>
        {isloading ? (
          <Loader />
        ) : (
          <DataGrid
            rows={[...data]}
            columns={columns}
            hideFooter
            getRowId={(e) => e?.rowid}
            slots={{ noRowsOverlay: NoDataImg }}
            // columnHeaderHeight={0}
            pageSizeOptions={[10]}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
           
            sx={{
              "& .MuiDataGrid-cell": {
                border: "0.1px solid #DDDDDD50",
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
}

