import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import searchicon from "../../Assets/image/dash/Minimalistic Magnifer.png";
import rateicon from "../../Assets/image/Trainers/Vector.png";
import AxiosInstance from "../../Common/Api/ApiHelper";
import dummyimage from "../../Assets/image/user/9720009.jpg";
import { toast } from "react-toastify";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import debounce from "lodash.debounce";
import order from "../../Assets/image/dash/Record Circle.png";
import { apiList } from "../../Common/Api/ApiList";
import ErrorToaster from "../../Component/ErrorToaster";
import viewicon from "../../Assets/image/user/223_frame.png";
import { PathList } from "../../Common/Routes/Path";
import { useNavigate } from "react-router-dom";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import moment from "moment";
import ResponsiveDatePickers from "../BookingScreen/DateComponent";
import DateLogo from "../../Assets/image/dash/Calendar Search.png";
import { useCallback } from "react";
import FetchSignedUrl from "../../Component/URL_Generator";

const typeOptionList = [
  { status: "All", key: "all" },
  { status: "Virtual", key: "is_virtual" },
  { status: "User place", key: "user_place" },
  { status: "Trainer place", key: "trainer_place" },
];

const PAGE_SIZE_OPTIONS = [30];
const TrainerManagementScreen = () => {
  const theme = useTheme();
  const [user, setUser] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(2);
  const [type, setType] = useState(["all"]);
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [ratingRange, setratingRange] = useState("All");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ageSelected, setAgeSelected] = useState(2);

  const StartDateMoment =
    startDate && moment(startDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  const EndDateMoment =
    endDate && moment(endDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

  const isStartDateFiltered =
    StartDateMoment && StartDateMoment.format("YYYY/MM/DD");
  const isEndDateFiltered = EndDateMoment && EndDateMoment.format("YYYY/MM/DD");

  const dateDifference =
    EndDateMoment && EndDateMoment.diff(StartDateMoment, "days");

  const fetchUserData = async (
    search = "",
    status,
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0],
    previousSearch = "",
    previousStatus = 2,
    type = [],
    prevType = [],
    ratingRange = "All",
    prevRatingRange = "All",
    dateDifference,
    date,
    ageSelected,
    prevAge
  ) => {
    try
    {
      setloading(true);
      let newPage = page;
      if (
        search !== previousSearch ||
        status !== previousStatus ||
        type !== prevType ||
        ratingRange !== prevRatingRange ||
        dateDifference !== date ||
        ageSelected !== prevAge
      )
      {
        newPage = 0;
        setPage(0);
      }

      if (dateDifference !== null && dateDifference <= 0)
      {
        toast.error("End date must be later than the start date.");
        setloading(false);
        setIsLoading(false);
        return;
      }
      let queryParams = new URLSearchParams({
        count: newPage * pageSize,
      });

      if (search.length > 0)
      {
        queryParams.append("search", search);
      }
      if (status !== 2)
      {
        queryParams.append("status", status);
      }
      if (ageSelected !== 2)
      {
        queryParams.append("agefilter", ageSelected);
      }
      if (dateDifference && dateDifference > 0)
      {
        queryParams.append("from_date", isStartDateFiltered);
        queryParams.append("to_date", isEndDateFiltered);
      }
      if (!type.includes("all"))
      {
        type.map((x) => queryParams.append(x, 1));
      }

      if (!(ratingRange === "All"))
      {
        queryParams.append("rat_filter", ratingRange);
      }
      const api = `${apiList.TrainersList}?${queryParams.toString()}`;
      const response = await AxiosInstance.get(api);
      const Rowdata = response?.data?.r?.data?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));

      if (Rowdata?.length > 0)
      {
        setUser(Rowdata);
        setTotalData(response?.data?.r?.total_count);
      } else
      {
        if (newPage === 0)
        {
          setUser([]);
        }
        setTotalData(0);
      }
      setIsLoading(false);
      setloading(false);
    } catch (error)
    {
      <ErrorToaster error={error} />;
      setloading(false);
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;
      const selectedValues = value.filter((val) => val !== "all");

      if (value.includes("all"))
      {
        if (type.includes("all"))
        {
          setType([...selectedValues]);
        } else
        {
          setType(typeOptionList.map((item) => item.key));
        }
      } else
      {
        if (type.includes("all"))
        {
          setType([]);
        } else
        {
          setType(
            selectedValues.length === typeOptionList.length - 1
              ? [...selectedValues, "all"]
              : selectedValues
          );
        }
      }
    },
    [type, typeOptionList, setType]
  );

  const renderValue = useCallback(
    (selected) => {
      const selectedLabels = selected.map((key) => {
        const selectedItem = typeOptionList.find((item) => item.key === key);
        return selectedItem ? selectedItem.status : "";
      });

      if (selectedLabels.includes("All"))
      {
        return "All";
      } else if (selectedLabels.length > 1)
      {
        return selectedLabels.join(", ");
      } else
      {
        return selectedLabels;
      }
    },
    [typeOptionList]
  );

  // const handleAction = async (id, action_status) => {
  //   try
  //   {
  //     setIsActionLoading(true);
  //     const res = await AxiosInstance.post(`${apiList.UserApproval}`, {
  //       id: id,
  //       status: action_status,
  //     });
  //     if (res?.data?.s === 1)
  //     {
  //       toast.success(res.data.m);
  //       fetchUserData(
  //         searchTerm,
  //         status,
  //         page,
  //         rowsPerPage,
  //         searchTermRef.current,
  //         statusRef.current,
  //         type,
  //         typeRef.current,
  //         ratingRange,
  //         ratingRef.current,
  //         dateDifference,
  //         dateDifferenceRef.current,
  //         ageSelected,
  //         ageRef.current
  //       );
  //     } else
  //     {
  //       toast.error(res.data.m);
  //     }
  //     setIsActionLoading(false);
  //   } catch (error)
  //   {
  //     <ErrorToaster error={error} />;
  //     setIsActionLoading(false);
  //   }
  // };

  const handleRatingChange = (e) => {
    setratingRange(e?.target?.value);
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Debounced fetch function
  const debouncedFetchUserData = debounce(
    (
      search,
      status,
      page,
      rowsPerPage,
      previousSearch,
      previousStatus,
      type,
      prevType,
      ratingRange,
      prevRatingRange,
      dateDifference,
      date,
      ageSelected,
      prevAge
    ) =>
      fetchUserData(
        search,
        status,
        page,
        rowsPerPage,
        previousSearch,
        previousStatus,
        type,
        prevType,
        ratingRange,
        prevRatingRange,
        dateDifference,
        date,
        ageSelected,
        prevAge
      ),
    300
  );

  const searchTermRef = useRef(searchTerm);
  const statusRef = useRef(status);
  const typeRef = useRef(type);
  const ratingRef = useRef(ratingRange);
  const ageRef = useRef(ageSelected);
  const dateDifferenceRef = useRef(dateDifference);

  useEffect(() => {
    debouncedFetchUserData(
      searchTerm,
      status,
      page,
      rowsPerPage,
      searchTermRef.current,
      statusRef.current,
      type,
      typeRef.current,
      ratingRange,
      ratingRef.current,
      dateDifference,
      dateDifferenceRef.current,
      ageSelected,
      ageRef.current
    );
    searchTermRef.current = searchTerm;
    statusRef.current = status;
    typeRef.current = type;
    ratingRef.current = ratingRange;
    dateDifferenceRef.current = dateDifference;
    ageRef.current = ageSelected;
    return () => {
      debouncedFetchUserData.cancel();
    };
  }, [
    searchTerm,
    status,
    page,
    rowsPerPage,
    type.length,
    type,
    ratingRange,
    dateDifference,
    ageSelected,
  ]);

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr.",
      width: 90,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * PAGE_SIZE_OPTIONS;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "id",
      headerName: "ID",
      width: 90,
      align: "center",
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "profile_img",
      headerName: "Profile",
      width: 90,
      headerAlign: "center",
      renderCell: (e) => <ProfileImageCell profileImg={e?.row?.profile_img} trainerId={e?.row?.id} />,
    },


    {
      headerClassName: "super-app-theme--header",
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 220,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "email",
      headerName: "Email",
      type: "number",
      flex: 1,
      minWidth: 300,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "age_confirmation",
      headerName: "Age",
      width: 100,
      headerAlign: "center",
      renderCell: ({ row }) => (row?.age_confirmation === 0 ? "-" : "18+"),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "rating_point",
      headerName: "Rate",
      type: "string",
      width: 150,
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Box
            sx={{
              gap: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            {e?.row?.rating_point > 0 ? (
              <>
                <Typography>{e?.row?.rating_point}</Typography>
                <img src={rateicon} alt="" style={{ marginBottom: "3px" }} />
              </>
            ) : (
              "-"
            )}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "trainer_place",
      headerName: "Type",
      width: 220,
      headerAlign: "center",
      renderCell: (cell) => {
        const { is_virtual, trainer_place, user_place } = cell.row;
        const renderTag = (text) => (
          <span
            style={{
              background: "#7AACFF",
              color: "white",
              borderRadius: "5px",
              margin: "3px",
              padding: "5px 8px",
            }}
          >
            {text}
          </span>
        );

        return (
          <Box>
            {is_virtual && trainer_place && user_place ? (
              renderTag("All Types")
            ) : !is_virtual && !trainer_place && !user_place ? (
              "-"
            ) : (
              <>
                {trainer_place === 1 ? renderTag("Trainer Place") : ""}
                {user_place === 1 ? renderTag("User Place") : ""}
                {is_virtual === 1 ? renderTag("Virtual") : ""}
              </>
            )}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "trained_people",
      headerName: "Trained People",
      type: "string",
      width: 150,
      headerAlign: "center",
      renderCell: (e) => {
        return (
          <Box
            sx={{
              gap: "2px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            {<Typography>{e?.row?.trained_people}</Typography>}
          </Box>
        );
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "created_at",
      headerName: "Date",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => moment(e?.row?.created_at).format("ll"),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "status",
      headerName: "Status",
      type: "string",
      width: 200,
      headerAlign: "center",
      renderCell: (e) => {
        const { status } = e.row;
        const statusStyle = {
          fontWeight: "500",
          opacity: "0.7",
          borderRadius: "10px",
          width: "100%",
          border: "1px solid",
          height: "70%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        };

        const boxStyle = {
          width: "100px",
          margin: "0 auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        };
        if (status === 1)
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "green",
                  borderColor: "green",
                }}
              >
                Active
              </span>
            </Box>
          );
        } else if (status === -1)
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "grey",
                  borderColor: "grey",
                }}
              >
                Restricted
              </span>
            </Box>
          );
        } else
        {
          return (
            <Box sx={boxStyle}>
              <span
                style={{
                  ...statusStyle,
                  color: "red",
                  borderColor: "red",
                }}
              >
                Deleted
              </span>
            </Box>
          );
        }
      },
    },
    // {
    //   headerClassName: "super-app-theme--header",
    //   field: "is_admin_approval",
    //   headerName: "Action",
    //   type: "string",
    //   width: 180,
    //   headerAlign: "center",
    //   renderCell: (e) => {
    //     const { is_admin_approval, id } = e.row;
    //     if (is_admin_approval === 0)
    //     {
    //       return (
    //         <span
    //           style={{
    //             color: "grey",
    //             fontWeight: "500",
    //             opacity: "0.7",
    //             letterSpacing: "0.025rem",
    //             // textTransform: "uppercase",
    //           }}
    //         >
    //           {/* Confirmed */}
    //           Pending
    //         </span>
    //       )
    //     } else if (is_admin_approval === 1)
    //     {
    //       return (
    //         <span
    //           style={{
    //             color: "green",
    //             fontWeight: "500",
    //             opacity: "0.7",
    //             letterSpacing: "0.025rem",
    //             // textTransform: "uppercase",
    //           }}
    //         >
    //           {/* Confirmed */}
    //           Approved
    //         </span>
    //       );
    //     } else if (is_admin_approval === -2)
    //     {
    //       return (
    //         <span
    //           style={{
    //             color: "green",
    //             fontWeight: "500",
    //             opacity: "0.7",
    //             letterSpacing: "0.025rem",
    //             // textTransform: "uppercase",
    //           }}
    //         >
    //           Deleted
    //         </span>
    //       );
    //     } else
    //     {
    //       return (
    //         <span
    //           style={{
    //             color: "red",
    //             fontWeight: "500",
    //             opacity: "0.7",
    //             letterSpacing: "0.025rem",
    //             // textTransform: "uppercase",
    //           }}
    //         >
    //           Rejected
    //         </span>
    //       );
    //     }
    //   },
    // },

    {
      headerClassName: "super-app-theme--header",
      headerName: "View",
      sortable: false,
      width: 100,
      headerAlign: "center",
      renderCell: (e) => (
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={viewicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${PathList.TrainerDetails}/${e?.row?.id}`)}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "start", lg: "end" },
          gap: "1rem",
        }}
      >
        {/* Search Input */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            // border: "1px solid red",
            maxWidth: "300px",
            height: "45px",
            alignSelf: { xs: "start", lg: "end" },
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{
              width: "100%",
              height: "45px",
              borderRadius: "10px",
              fontSize: "14px",
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              border: "none",
              outline: "none",
              paddingLeft: "20px",
            }}
          />
          <span
            style={{
              position: "absolute",
              right: "15px",
              top: "10px",
            }}
          >
            <img src={searchicon} alt="Search" />
          </span>
        </Box>

        {/* Filters */}
        <Grid container spacing={2} justifyContent={"end"}>
          {/* Rating Filter */}
          <Grid item xs={12} sm={4} md={4} lg={2} xl={1.5}>
            <Box
              sx={{
                padding: "8px",
                borderRadius: "8px",
                fontSize: "14px",
                boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  pb: "0.15rem",
                }}
              >
                <img src={order} height={17} width={17} alt="" />
                Rating
              </Box>
              <Select
                value={ratingRange}
                fullWidth
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  ".MuiSelect-select": {
                    padding: "8px 12px",
                  },
                }}
                onChange={handleRatingChange}
              >
                {["All", "5", "4", "3", "2", "1"].map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>

          {/* Type Filter */}
          <Grid item xs={12} sm={4} md={4} lg={2} xl={1.5}>
            <Box
              sx={{
                padding: "8px",
                borderRadius: "8px",
                fontSize: "14px",
                boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  pb: "0.15rem",
                }}
              >
                <img src={order} height={17} width={17} alt="" />
                Type
              </Box>
              <Select
                value={type}
                fullWidth
                multiple
                renderValue={renderValue}
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  ".MuiSelect-select": {
                    padding: "8px 12px",
                  },
                }}
                onChange={handleChange}
              >
                {typeOptionList.map((item, i) => (
                  <MenuItem key={item.key + i} value={item.key}>
                    <Checkbox
                      style={{ color: "#7AACFF" }}
                      checked={type.includes(item.key) || type.includes("all")}
                    />
                    {item.status}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>

          {/* Age Filter */}
          <Grid item xs={12} sm={4} md={4} lg={2} xl={1.5}>
            <Box
              sx={{
                padding: "8px",
                borderRadius: "8px",
                fontSize: "14px",
                boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  pb: "0.15rem",
                }}
              >
                <img src={order} height={17} width={17} alt="" />
                Age
              </Box>
              <Select
                value={ageSelected}
                fullWidth
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  ".MuiSelect-select": {
                    padding: "8px 12px",
                  },
                }}
                onChange={(e) => setAgeSelected(e.target.value)}
              >
                {[
                  { id: 2, status: "All" },
                  { id: 1, status: "Above 18" },
                  { id: 0, status: "Below 18" },
                  // { id: 3, status: "30-39 yrs" },
                  // { id: 4, status: "40-49 yrs" },
                  // { id: 5, status: "50 yrs and above" },
                ].map((x, i) => (
                  <MenuItem key={i} value={x.id}>
                    {x.status}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={2} xl={1.5}>
            <Box
              sx={{
                padding: "8px",
                // width: { xs: "100%", md: "150px", lg: "180px", xl: "200px" },
                borderRadius: "8px",
                fontSize: "14px",
                boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "0.5rem",
                  pb: "0.15rem",
                  color: theme.palette.text.primary,
                }}
              >
                <img src={order} height={17} width={17} alt="" srcSet="" />
                Status
              </Box>

              <Select
                value={status}
                fullWidth
                sx={{
                  backgroundColor: "#f5f5f5",
                  borderRadius: "8px",
                  ".MuiSelect-select": {
                    padding: "8px 12px",
                  },
                }}
                onChange={(e) => setStatus(e.target.value)}
              >
                {[
                  {
                    id: 2,
                    status: "All",
                  },
                  // {
                  //   id: 0,
                  //   status: "Pending",
                  // },
                  // {
                  //   id: -1,
                  //   status: "Rejected",
                  // },
                  // {
                  //   id: 1,
                  //   // status: "Confirmed",
                  //   status: "Approved",
                  // },

                  {
                    id: 4,
                    status: "Restricted",
                  },
                  {
                    id: 3,
                    status: "Deleted",
                  },
                ].map((x, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={x?.id}
                      style={{ display: "block" }}
                    >
                      {x?.status}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Grid>

          {/* Date Range Filter */}
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
            <Box
              sx={{
                padding: "8px",
                borderRadius: "8px",
                fontSize: "14px",
                boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "0.5rem",
                  pb: "0.15rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <img src={DateLogo} height={17} width={17} alt="" />
                  Date
                </Box>
                <Box
                  onClick={() => {
                    setStartDate(null);
                    setEndDate(null);
                  }}
                  sx={{
                    cursor: "pointer",
                    color: "#3498db",
                    display: dateDifference !== null ? "block" : "none",
                    "&:hover": { fontWeight: "500" },
                  }}
                >
                  Clear
                </Box>
              </Box>
              <ResponsiveDatePickers
                endDate={endDate}
                setEndDate={setEndDate}
                startDate={startDate}
                setStartDate={setStartDate}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          // minHeight: "550px",
          height: "calc(100vh - 200px)",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "5px",
          mt: 3,
          p: 3,
        }}
      >
        <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
          Trainers List
        </Typography>
        {/* DataGrid with filtered data */}
        {/* <Box sx={{ height: "500px" }}> */}
        {isLoading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              height: "calc(100% - 50px)",
            }}
          >
            <DataGrid
              rows={user}
              rowCount={-1}
              pagination
              paginationMode="server"
              disableColumnMenu={true}
              // disableColumnSorting={true}
              disableAutosize={true}
              disableColumnFilter={true}
              disableRowSelectionOnClick={true}
              getRowId={(e) => e?.rowid}
              hideFooter
              loading={loading}
              slots={{ noRowsOverlay: NoDataImg }}
              onRowClick={(e) => {
                navigate(`${PathList.TrainerDetails}/${e.row.id}`);
              }}
              columns={columns}
              sx={{
                display: "flex",
                cursor: "pointer",
                justifyContent: "center",
                ".MuiDataGrid-cell": { textAlign: "center" },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  color: "white",
                },
                "& .super-app-theme--header": {
                  background: theme.palette.primary.Blend,
                },
                "& .MuiDataGrid-cell": {
                  // border: "0.1px solid #DDDDDD50",
                  "&:focus": {
                    outline: "none",
                  },
                },
              }}
              pageSizeOptions={[10]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
            <TablePagination
              component="div"
              count={totalData}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          </Box>
        )}
        {/* </Box> */}
      </Box>
    </>
  );
};

export default TrainerManagementScreen;

const ProfileImageCell = ({ profileImg, trainerId }) => {
  const path = profileImg?.split("https://instatrainme-api.s3.amazonaws.com/")[1];
  const [signedUrl, setSignedUrl] = useState(null);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUrl = async () => {
      if (path)
      {
        const url = await FetchSignedUrl({ path });
        setSignedUrl(url);
      }
    };
    fetchUrl();
  }, [path]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={signedUrl || dummyimage} // Show dummyimage if URL is still loading
        alt="Profile"
        onClick={() => navigate(`${PathList.TrainerDetails}/${trainerId}`)}
        style={{
          height: "40px",
          cursor: "pointer",
          width: "50px",
          borderRadius: "10px",
          border: "1px solid #E0E0E0",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};
