import { getToken } from "firebase/messaging";
import AxiosInstance from "../Common/Api/ApiHelper";
import { apiList } from "../Common/Api/ApiList";
import { messaging } from "./Firebase-config";

const getFCMToken = async (registration = null) => {
  try
  {
    return await getToken(messaging, {
      vapidKey:
        "BB43enqkfkb0WquGwZFz4XdHOYy0zcOP203dj9hV5gf6RG1zuEmOLwg9782t4-i6vsxEhgUxxehAUN8ZUQLBN5E",
      serviceWorkerRegistration: registration,
    });
  } catch (error)
  {
    console.error("Error retrieving FCM token:", error);
    return null;
  }
};

const addUpdateFCM = async (x) => {
  try
  {
    const userInfo = localStorage.getItem("userInfo");
    if (!userInfo) return;

    const userData = JSON.parse(userInfo);
    let device_id =
      localStorage.getItem("device_id") || `U-${userData.id}-${Date.now()}`;
    localStorage.setItem("device_id", device_id);

    let fcm = await getFCMToken();
    if (!fcm && x !== "0")
    {
      console.warn("FCM token not available, skipping update.");
      return;
    }

    const data = new FormData();
    data.append("fcm_token", x === "0" ? 0 : fcm);
    data.append("device_id", device_id);
    data.append("u_id", userData.id);

    await AxiosInstance.post(apiList.FCMTokenUpadate, data);
  } catch (error)
  {
    console.error("Error in addUpdateFCM:", error);
  }
};

export default addUpdateFCM;
