import { Box, IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import BookingListDataGrid from "./BookingListDataGrid";
import DropItemsComponent from "./DropItemsComponent";
import SearchIcon from "../../Assets/image/dash/Minimalistic Magnifer.png";

export default function BookingScreen() {
  const [search, setsearch] = useState("");
  const [paymentFilter, setPaymentFilter] = useState("All");
  const [orderStatusFilter, setOrderStatusFilter] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  return (
    <Box
      sx={{
        width: "100%",
        height: `calc(100vh - 77px)`,
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        gap: "1.5rem",
        flexDirection: "column",
        paddingBottom: "1rem",
      }}
    >
      <Box
        sx={{
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          // border: "solid red",
          justifyContent: { xs: "start", lg: "space-between" },
          alignItems: { xs: "start", md: "end" },
          gap: "1rem",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box>
          <OutlinedInput
            type="text"
            name="search"
            id="search"
            value={search}
            onChange={(e) => setsearch(e.target.value)}
            placeholder="Search"
            variant="outlined"
            sx={{
              borderRadius: "8px",
              padding: "10px", // Adjust padding here
              boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              "& .MuiOutlinedInput-input": {
                padding: "10px", // Adjust padding for input field
              },
              "& fieldset": {
                border: "none",
              },
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton>
                  <img src={SearchIcon} alt="" srcSet="" />
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: { xs: "start", md: "center" },
            gap: "0.5rem",
            width: { xs: "100%", lg: "80%" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <DropItemsComponent
            paymentFilter={paymentFilter}
            setPaymentFilter={setPaymentFilter}
            setOrderStatusFilter={setOrderStatusFilter}
            orderStatusFilter={orderStatusFilter}
            setEndDate={setEndDate}
            endDate={endDate}
            startDate={startDate}
            setStartDate={setStartDate}
          />
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <BookingListDataGrid
          search={search}
          paymentFilter={paymentFilter}
          orderStatusFilter={orderStatusFilter}
          endDate={endDate}
          startDate={startDate}
        />
      </Box>
    </Box>
  );
}
