import { Badge, Box, IconButton, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CounterComponent from "./CounterComponent";
import NewUsersListComponent from "./NewUsersListComponent";
import NewTrainerListComponent from "./NewTrainerListComponent";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { PathList } from "../../Common/Routes/Path";
import { getToken } from "firebase/messaging";
import { messaging } from "../../Firebase/Firebase-config";
import addUpdateFCM from "../../Firebase/addUpadateFCM";

export default function DashboardScreen() {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [data, setData] = useState();
  const [isloading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [unReadCount, setUnReadCount] = useState(0);
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const userID = userData?.id;

  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }

  const fetchData = async () => {
    try {
      const res = await AxiosInstance.get(apiList.Dashboard);
      if (res?.data?.s === 1) {
        setData(res?.data?.r);
        if (res?.data?.r?.new_users?.length > 0) {
          const updatedData = res?.data?.r?.new_users?.map((x, i) => {
            return { ...x, rowid: i + 1 };
          });
          setUsers(updatedData);
        }
        if (res?.data?.r?.new_trainers?.length > 0) {
          const updatedData = res?.data?.r?.new_trainers?.map((x, i) => {
            return { ...x, rowid: i + 1 };
          });
          setTrainers(updatedData);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
      );
    }
  };

  const GetUnreadCount = async (id) => {
    try {
      let res = await AxiosInstance.get(apiList.getUnreadCount, {
        params: { u_id: id },
      });
      if (res?.data?.s) {
        setUnReadCount(res?.data?.r ?? 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    addUpdateFCM();
  }, []);
  useEffect(() => {
    GetUnreadCount(userID);
  }, [userID]);
  return (
    <Box
      sx={{
        width: "100%",
        height: `calc(100vh - 77px)`,
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        gap: "1rem",
        flexDirection: "column",
        boxSizing: "border-box",
        // border: 'solid red',
        // overflow: "scroll",
      }}
    >
      <Box sx={{ position: "absolute", top: 20, right: 30 }}>
        <IconButton
          onClick={() => navigate(PathList.request)}
          aria-label={notificationsLabel(unReadCount)}
        >
          <Badge badgeContent={unReadCount} color="error">
            <NotificationsIcon style={{ color: "#000" }} />
          </Badge>
        </IconButton>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: "1rem",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CounterComponent data={data} />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: "calc(100dvh - 180px)",
          gap: "1rem",
          boxSizing: "border-box",
          paddingBottom: "1rem",
          flexDirection: { xs: "column", xl: "row" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "0 1rem 1rem",
            borderRadius: "4px",
            border: `1px solid ${theme.palette.border.main}`,
          }}
        >
          <NewUsersListComponent data={users} isloading={isloading} />
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "0 1rem 1rem",
            borderRadius: "4px",
            border: `1px solid ${theme.palette.border.main}`,
          }}
        >
          <NewTrainerListComponent
            fetchData={fetchData}
            data={trainers}
            isloading={isloading}
          />
        </Box>
      </Box>
    </Box>
  );
}
