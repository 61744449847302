import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../../Assets/image/dash/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { NavMenuList } from "./MenuList";
import { Divider, Typography, useTheme } from "@mui/material";
import { PathList } from "../../Common/Routes/Path";
import LogoutModal from "./Logout/LogoutModal";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
const drawerWidth = 240;

function MainLayout({ element }) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box
      sx={{
        bgcolor: theme.palette.background.main,
        height: "100vh",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ height: `calc(100vh - 95px )` }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: "2rem",
            height: "150px",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ cursor: "pointer" }}
            srcSet=""
            onClick={() => navigate(PathList.Dashboard)}
          />
        </Box>
        <List sx={{ height: `calc(100vh - 250px )`, overflow: "auto" }}>
          {NavMenuList.map(({ title, icon, active, path }, i) => (
            <ListItem
              key={i}
              disablePadding
              sx={{
                m: "0.5rem 0",
                "&:hover": {
                  background: theme.palette.primary.BlendO,
                },
                borderRadius: "5px",
              }}
            >
              <Link
                style={{
                  // color:theme.palette.text.main,
                  textDecoration: "none",
                  width: "100%",
                }}
                to={path}
                onClick={handleDrawerToggle}
              >
                <ListItemButton
                  sx={{
                    background:
                      location.pathname.includes(path) ||
                      location.state?.From === path
                        ? theme.palette.primary.Blend
                        : theme.palette.background.main,
                    borderRadius: "5px",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "44px",
                    }}
                  >
                    {location.pathname.includes(path) ||
                    location.state?.From === path ? (
                      <img
                        src={active}
                        style={{
                          boxSizing: "border-box",
                          height: "30px",
                          width: "30px",
                        }}
                        alt=""
                        srcSet=""
                      />
                    ) : (
                      <img
                        src={icon}
                        style={{
                          boxSizing: "border-box",
                          height: "30px",
                          width: "30px",
                        }}
                        alt=""
                        srcSet=""
                      />
                    )}{" "}
                  </ListItemIcon>
                  <ListItemText
                    primary={title}
                    sx={{
                      color:
                        location.pathname.includes(path) ||
                        location.state?.From?.includes(path)
                          ? theme.palette.text.secondary
                          : theme.palette.text.main,
                    }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
      {/* Logout Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "50px",
          boxSizing: "border-box",
        }}
      >
        <LogoutModal />
      </Box>

      <Divider />
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          //   width: { sm: `calc(100% - ${drawerWidth}px)` },
          //   ml: { sm: `${drawerWidth}px` },
          display: { md: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            fontWeight={"600"}
            color={"#eee"}
            onClick={handleDrawerToggle}
          >
            {location.pathname === PathList.Dashboard
              ? "Dashboard"
              : location.pathname === PathList.Booking
              ? "Booking"
              : location.pathname === PathList.PaymentHistory
              ? "Payment History"
              : location.pathname === PathList.Notification
              ? "Notifications"
              : location.pathname === PathList.Support
              ? "Support"
              : location.pathname === PathList.Trainer
              ? "Trainer Management"
              : location.pathname === PathList.PaymentHistory
              ? "User Management"
              : location.pathname.includes(PathList.UserDetails)
              ? "User Details"
              : location.pathname === PathList.PlanDetails
              ? "Training Plan Details"
              : location.pathname.includes(PathList.Booking_Details) &&
                "Booking Details"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100dvh",
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Box sx={{ display: { md: "none" }, mb: 2 }}>
          <Toolbar />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            m={
              location.pathname === PathList.Notification ||
              location.pathname === PathList.Support ||
              location.pathname === PathList.AppIssue
                ? "1rem 1rem 1rem 1.8rem" // "0"
                : "1rem 1rem 1rem 1.8rem"
            }
            fontSize={"28px"}
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {(location.pathname.includes(PathList.request) ||
              location.pathname.includes(PathList.Booking_Details) ||
              location.pathname.includes(PathList.Chat) ||
              location.pathname.includes(PathList.TrainerDetails) ||
              location.pathname.includes(PathList.PlanDetails) ||
              location.pathname.includes(PathList.UserDetails)) && (
              <ArrowBackIosRoundedIcon
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    color: "#3C75D4",
                  },
                }}
                onClick={() => navigate(-1)}
              />
            )}
            {location.pathname === PathList.Dashboard
              ? "Dashboard"
              : location.pathname === PathList.Booking
              ? "Booking"
              : location.pathname === PathList.PaymentHistory
              ? "Payment History"
              : location.pathname === PathList.Trainer
              ? "Trainer Management"
              : location.pathname === PathList.Setting
              ? "Setting"
              : location.pathname === PathList.Notification //reomve
              ? "Notification" //reomve
              : location.pathname === PathList.AppIssue //reomve
              ? "Application Issues" //reomve
              : location.pathname === PathList.Support //reomve
              ? "Support" //reomve
              : location.pathname === PathList.request //reomve
              ? "Manage New Requests"
              : location.pathname === PathList.UserManagement
              ? "User Management"
              : location.pathname.includes(PathList.Chat)
              ? "Chat Support"
              : location.pathname.includes(PathList.UserDetails)
              ? "User Details"
              : location.pathname.includes(PathList.TrainerDetails)
              ? "Trainer Details"
              : location.pathname.includes(PathList.Booking_Details)
              ? "Booking Details"
              : location.pathname.includes(PathList.PlanDetails) &&
                "Training Plan Details"}
          </Typography>
        </Box>
        <Box
          // m={
          //   location.pathname === PathList.Notification
          //     ? "0"
          //   //   : location.pathname === PathList.FAQs
          //   //   ? "0"
          //   //   : location.pathname === PathList.Support
          //   //   ? "0"
          //   //   : location.pathname === PathList.AppIssue
          //   //   ? "0"
          //     :
          //   "0 1.8rem"
          // }
          m={"0 1.8rem"}
        >
          {element}
        </Box>
      </Box>
    </Box>
  );
}

export default MainLayout;
