import AxiosInstance from "../Common/Api/ApiHelper";
import { apiList } from "../Common/Api/ApiList";


const FetchSignedUrl = async (path) => {
    if (!path?.path)
    {
        return null;
    }
    try
    {
        const res = await AxiosInstance.get(apiList.getSignedURL, {
            params: {
                bucketName: "instatrainme-api",
                objectKey: path?.path,
            },
        });
        if (res.data?.s)
        {
            return res?.data?.r; // Return the signed URL
        } else
        {
            throw new Error("Invalid response format");
        }
    } catch (error)
    {
        console.error("Error fetching signed URL:", error);
        return null;
    }
};

export default FetchSignedUrl;
