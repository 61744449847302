import { Box, Tab, Tabs, Typography } from "@mui/material";
import NewRequestsTab from "./Tabs/RequestTab";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import PaymnetTab from "./Tabs/PaymentTab";

const ManageNewRequests = () => {
  const [value, setValue] = useState(0);
  const location = useLocation();
  const id = location?.state?.planId;

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabStyle = {
    color: "grey",
    textTransform: "none",
    fontSize: "18px",
    fontWeight: "500",
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
        >
          {["All", "Location", "Category", "Training Plan"]?.map((x,i) => (
            <Tab label={x} {...a11yProps(0)} sx={TabStyle} key={i}/>
          ))}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <NewRequestsTab value={value} id={id} type={0} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <NewRequestsTab value={value} id={id} type={9} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <NewRequestsTab value={value} id={id} type={8} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <PaymnetTab value={value} id={id} type={1} />
      </CustomTabPanel>
    </Box>
  );
};

export default ManageNewRequests;
