import { Box, TablePagination, Tooltip, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import AxiosInstance from "../../Common/Api/ApiHelper";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import { apiList } from "../../Common/Api/ApiList";
import ErrorToaster from "../../Component/ErrorToaster";

import moment from "moment";


const PAGE_SIZE_OPTIONS = [30];
const TrainerPlanTab = ({ value, id }) => {
  const theme = useTheme();
  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [loading, setloading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);


  const fetchData = async (
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0],
    value,
    id,
  ) => {
    try {
      setloading(true);
      let newPage = page;
   
      let queryParams = new URLSearchParams({
        count: newPage * pageSize,
        training_plan_id: id,
      });

   
      const api =
        value === 0
          ? `${apiList.getTrainerPlans}?${queryParams.toString()}`
          : `${apiList.getUserProgress}?${queryParams.toString()}`;
      const response = await AxiosInstance.get(api);
      const Rowdata = response?.data?.r?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));

      if (Rowdata?.length > 0) {
        setUser(Rowdata);
        setTotalData(response?.data?.c ?? 0);
      } else {
        if (newPage === 0) {
          setUser([]);
        }
        setTotalData(0);
      }
      setIsLoading(false);
      setloading(false);
    } catch (error) {
      <ErrorToaster error={error} />;
      setloading(false);
      setIsLoading(false);
    }
  };


  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchData(page, PAGE_SIZE_OPTIONS, value, id);
  }, [page, value, id]);

  const columns = [
    {
      headerClassName: "super-app-theme--header",
      field: "rowid",
      headerName: "Sr.",
      width: 80,
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.rowid + page * PAGE_SIZE_OPTIONS;
      },
    },
    {
      headerClassName: "super-app-theme--header",
      field: "plan_date",
      headerName: "Date",
      width: 120,
      headerAlign: "center",
      renderCell: (cell) => moment(cell?.row?.plan_date).format("ll"),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "activity_name",
      headerName: "Activity",
      type: "number",
      flex: 1,
      minWidth: 250,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "intensity",
      headerName: "Intensity",
      type: "number",
      width: 150,
      headerAlign: "center",
      renderCell: (cell) =>
        cell?.value?.charAt(0).toUpperCase() +
        cell?.value?.slice(1)?.toLowerCase(),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "sets",
      headerName: "Sets",
      type: "number",
      width: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "reps",
      headerName: "Reps",
      type: "number",
      width: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "exercise_duration",
      headerName: "Exercise duration",
      type: "number",
      width: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "rest_duration",
      headerName: "Rest duration",
      type: "number",
      width: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "zone",
      headerName: "Zone",
      type: "number",
      width: 150,
      headerAlign: "center",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "distance",
      headerName: "Distance",
      type: "number",
      width: 200,
      headerAlign: "center",
      renderCell: (cell) => cell?.value + " km",
    },
    {
      headerClassName: "super-app-theme--header",
      field: "focus_area",
      headerName: "Focus Area",
      type: "number",
      width: 200,
      headerAlign: "center",
      renderCell: (cell) => (
        <Tooltip title={cell?.value || "-"} arrow>
          <span>{cell?.value !== "" ? cell?.value : "-"}</span>
        </Tooltip>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "notes",
      headerName: "Notes",
      type: "number",
      width: 200,
      headerAlign: "center",
      renderCell: (cell) => (
        <Tooltip title={cell?.value ?? "-"} arrow>
          <span>{cell?.value !== "" ? cell?.value : "-"}</span>
        </Tooltip>
      ),
    },
    {
      headerClassName: "super-app-theme--header",
      field: "equipment",
      headerName: "Equipment",
      type: "number",
      width: 200,
      headerAlign: "center",
      renderCell: (cell) => (
        <Tooltip title={cell?.value ?? "-"} arrow>
          <span>{cell?.value !== "" ? cell?.value : "-"}</span>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // minHeight: "550px",
          height: "calc(100vh - 350px)",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "5px",
          // mt: 3,
          // p: 3,
        }}
      >

        {isLoading ? (
          <Loader />
        ) : (
          <Box
            sx={{
              height: "calc(100% - 50px)",
            }}
          >
            <DataGrid
              rows={user}
              rowCount={-1}
              pagination
              paginationMode="server"
              disableColumnMenu={true}
              // disableColumnSorting={true}
              disableAutosize={true}
              disableColumnFilter={true}
              disableRowSelectionOnClick={true}
              getRowId={(e) => e?.rowid}
              hideFooter
              loading={loading}
              slots={{ noRowsOverlay: NoDataImg }}
              columns={columns}
              sx={{
                display: "flex",
                justifyContent: "center",
                ".MuiDataGrid-cell": { textAlign: "center" },
                ".MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "600",
                  color: "white",
                },
                "& .super-app-theme--header": {
                  background: theme.palette.primary.Blend,
                },
              }}
              pageSizeOptions={[10]}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
            <TablePagination
              component="div"
              count={totalData}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
              rowsPerPageOptions={PAGE_SIZE_OPTIONS}
            />
          </Box>
        )}
        {/* </Box> */}
      </Box>
    </>
  );
};

export default TrainerPlanTab;
