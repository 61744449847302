import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NoDataImg from "../../../Common/NoData";
import Loader from "../../../Common/Loader";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import AxiosInstance from "../../../Common/Api/ApiHelper";
import { apiList } from "../../../Common/Api/ApiList";
// import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function Experince({ data, getData }) {
  const [addresses, setAddresses] = useState([]);
  const [loading, setloading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleAction = (action) => {
    if (selectedItem)
    {
      handleLocationPrefAction(selectedItem.id, action, selectedItem.key);
    }
    handleClose();
  };
  const d = data?.options?.basic_details;

  const GetAddressFromLng = async (lat, lng) => {
    try
    {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxwKI7SmYgpPKeO2-vkOda_SDEdqgzYyE`
      );
      const data = await response?.json();
      if (data?.status === "OK")
      {
        return data?.results[0]?.formatted_address;
      } else
      {
        throw new Error("Failed to fetch address");
      }
    } catch (error)
    {
      console.log(error.message);
      return null;
    }
  };

  const handleLocationPrefAction = async (userId, status, key) => {
    try
    {
      setIsActionLoading(true);
      let f = new FormData();
      f.append("u_id", userId);
      f.append(`${key}`, status);
      const response = await AxiosInstance.post(
        apiList?.TrainerLocationPreferenceAction,
        f
      );
      if (response?.data?.s)
      {
        getData();
      } else
      {
        toast.error(response?.data?.m);
      }
      setIsActionLoading(false);
    } catch (error)
    {
      console.log(error);
      setIsActionLoading(false);
    }
  };

  useEffect(() => {
    const fetchAddresses = async () => {
      try
      {
        setloading(true);
        if (d?.locations)
        {
          const locations = d?.locations;
          const newAddresses = await Promise.all(
            locations?.map(async (location) => {
              return await GetAddressFromLng(location?.lat, location?.lang);
            })
          );

          const validAddresses = newAddresses.filter(
            (address) => address !== null
          );

          setAddresses(validAddresses);
          setloading(false);
        }
      } catch (error)
      {
        setloading(false);
        console.log(error);
      }
    };

    fetchAddresses();
  }, [data]);
  return (
    <Box
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        width: "100%",
        maxHeight: { md: "calc(100vh - 350px)" },
        // minHeight: '350px',
        overflow: { md: "scroll" },
        borderRadius: "8px",
      }}
      className="HideScrollBar"
    >
      <Typography
        sx={{
          p: "1rem",
          background:
            "linear-gradient(181.45deg, #7AACFF -80.86%, rgba(60, 117, 213, 0.98) 98.77%)",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          color: "white",
          height: "50px",
          position: "sticky",
          top: "0",
          zIndex: 1,
        }}
      >
        Experiences
      </Typography>
      <Box
        className="HideScrollBar"
        sx={{ height: "calc(100% - 50px)", overflow: "scroll" }}
      >
        {d?.experiences?.length > 0 ? (
          d?.experiences?.map((experience, index) => {
            return (
              <>
                <Box key={index+'i'} sx={{ px: 2 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      pt: 1,
                    }}
                  >
                    {experience?.gym_name}
                  </Typography>
                  <Typography
                    sx={{
                      pb: 1,
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {experience?.years} Year
                  </Typography>
                  {d?.experiences?.length - 1 !== index ? (
                    <hr style={{ background: "#CECECE", opacity: "0.4" }} />
                  ) : null}
                </Box>
              </>
            );
          })
        ) : (
          <Box sx={{ padding: "1rem" }}>
            <NoDataImg />
          </Box>
        )}
        <Typography sx={{ p: 1, background: "#e2e2e2", fontWeight: "bold" }}>
          Location Preferences
        </Typography>

        {/*
         --- ACTION STATUS CODE ---
         * Pending = 0
         * Request = 2
         * Approve = 1
         * Reject = -1
         * Restrict = -2
         * unRestrict = 1 
         */}


        <Box sx={{ display: "flex", p: 2, gap: "5px", flexWrap: "wrap" }}>
          {d?.location_preferences?.my_place !== 0 ||
            d?.location_preferences?.is_virtual !== 0 ||
            d?.location_preferences?.user_place !== 0 ? (
            <>
              {[
                {
                  id: data?.id,
                  key: "user_place",
                  lable: "User Place",
                  status: d?.location_preferences?.user_place,
                  // status: -1,
                  isVisible: d?.location_preferences?.user_place !== 0,
                },
                {
                  id: data?.id,
                  key: "trainer_place",
                  lable: "My Place",
                  status: d?.location_preferences?.my_place,
                  // status: -2,
                  isVisible: d?.location_preferences?.my_place !== 0,
                },
                {
                  id: data?.id,
                  key: "is_virtual",
                  lable: "Virtual",
                  status: d?.location_preferences?.is_virtual,
                  // status: 2,
                  isVisible: d?.location_preferences?.is_virtual !== 0,
                },
              ]?.map((x, i) => {
                let bgColor =
                  x?.status === 1
                    ? "#C2EAC2"
                    : x?.status === 2
                      ? "#FFE6C1"
                      : x?.status === -1
                        ? "#FFC1C1"
                        : x?.status === -2 && "#D8D8D8";
                return (
                  <Box 
                  key={i}
                    sx={{
                      border: "1px solid #e5e5e5",
                      p: 1,
                      borderRadius: "5px",
                      width: "100%",
                      maxWidth: "400px",
                      display: x?.isVisible ? "flex" : "none",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "100px" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          background: bgColor,
                          p: 1,
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        {x?.lable}
                      </Typography>
                    </Box>
                    {x?.status === -2 && (
                      <span style={{ color: "#ff5757" }}>Restricted</span>
                    )}
                    <Box>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {(selectedItem?.status === 2 ||
                          selectedItem?.status === -1) && (
                            <MenuItem onClick={() => handleAction(1)}>
                              Approve
                            </MenuItem>
                          )}
                        {(selectedItem?.status === 2 ||
                          selectedItem?.status === 1) && (
                            <MenuItem onClick={() => handleAction(-1)}>
                              Reject
                            </MenuItem>
                          )}
                        {selectedItem?.status === 1 && (
                          <MenuItem onClick={() => handleAction(-2)}>
                            Restrict
                          </MenuItem>
                        )}
                        {selectedItem?.status === -2 && (
                          <MenuItem onClick={() => handleAction(1)}>
                            Unrestrict
                          </MenuItem>
                        )}
                      </Menu>
                    </Box>
                    <IconButton onClick={(e) => handleClick(e, x)}>
                      <MoreVertIcon />
                    </IconButton>
                  </Box>
                );
              })}
            </>
          ) : (
            <Box sx={{ padding: "1rem", width: "100%" }}>
              <NoDataImg />
            </Box>
          )}
        </Box>
        {/* <Box sx={{ display: "flex", p: 2, gap: "5px" }}>
          {d?.location_preferences?.my_place === 1 || d?.location_preferences?.is_virtual === 1 ||
            d?.location_preferences?.user_place === 1 ? (
            <>
              <Typography
                sx={{
                  fontSize: "16px",
                  background: "#CEE5FF",
                  p: 1,
                  borderRadius: "5px",
                  display:
                    d?.location_preferences?.user_place === 1
                      ? "block"
                      : "none",
                }}
              >
                {d?.location_preferences?.user_place === 1 && "User Place"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  background: "#CEE5FF",
                  p: 1,
                  borderRadius: "5px",
                  display:
                    d?.location_preferences?.my_place === 1 ? "block" : "none",
                }}
              >
                {d?.location_preferences?.my_place === 1 && "My Place"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  background: "#CEE5FF",
                  p: 1,
                  borderRadius: "5px",
                  display:
                    d?.location_preferences?.is_virtual === 1 ? "block" : "none",
                }}
              >
                {d?.location_preferences?.is_virtual === 1 && "Virtual"}
              </Typography>
            </>
          ) : (
            <Box sx={{ padding: "1rem", width: "100%" }}>
              <NoDataImg />
            </Box>
          )}
        </Box> */}
        <Typography sx={{ p: 1, background: "#e2e2e2", fontWeight: "bold" }}>
          Location
        </Typography>
        <Box
          sx={{
            p: 2,
            gap: "5px",
            display: "flex",
          }}
        >
          {loading ? (
            <Loader />
          ) : addresses && addresses?.length > 0 ? (
            addresses.map((trainerlocation, i) => {
              return (
                <>
                  <Typography
                    key={i}
                    sx={{
                      fontSize: "16px",
                      background: "#CEE5FF",
                      p: 1,
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(
                        "https://www.google.com/maps/search/" + trainerlocation
                      );
                    }}
                  >
                    {trainerlocation}
                  </Typography>
                </>
              );
            })
          ) : (
            <Box sx={{ padding: "1rem", width: "100%" }}>
              <NoDataImg />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
