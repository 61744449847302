import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Loader from "../../Common/Loader";
import ErrorToaster from "../../Component/ErrorToaster";
import TrainerPlanTab from "./TrainerPlanTab";

const TrainingPlanDetails = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState({});
  const location = useLocation();
  const id = location.state.planId;

  const fetchData = async (id) => {
    try {
      const response = await AxiosInstance.get(
        `${apiList.getTrainingPlanDetailsById}?id=${id}`
      );
      if (response?.data?.r) {
        setData(response?.data?.r);
        setIsLoading(false);
      }
    } catch (error) {
      <ErrorToaster error={error} />;
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const basicDetails = [
    {
      title: "Plan Type",
      value:
        data?.training_sub_cat_id == 181
          ? data?.plan_duration_val + " " + data?.plan_duration
          : data?.plan_type ?? null,
    },
    {
      title: "Category",
      value: data?.cat_name ?? null,
    },
    {
      title: "Start Date",
      value: moment(data?.start_date).format("ll") ?? null,
    },
    {
      title: "Sub Category",
      value: data?.sub_cat_name ?? null,
    },
    {
      title: "End Date",
      value: moment(data?.end_date).format("ll") ?? null,
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
          py: 2,
        }}
      >
        <Box
          sx={{
            height: "calc(100vh - 100px)",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    m: 3,
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    alignItems: { xs: "center", md: "start" },
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      pl: { xs: 3, md: 0 },
                      display: "flex",
                      flexDirection: { xs: "column", lg: "row" },
                      justifyContent: "start",
                      width: "100%",
                      gap: { lg: 5 },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        // flexDirection: "column",
                        justifyContent: "start",
                        gap: 1,
                        flexWrap: "wrap",
                        maxWidth: "800px",
                      }}
                    >
                      {basicDetails?.map((x,i) => (
                        <Box
                          key={i}
                          sx={{
                            width: "fit-content",
                            minWidth: "300px",
                            display: "flex",
                            gap: 1,
                            // border: "1px solid red",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              // fontWeight: "500",
                              color: "#252525",
                            }}
                          >
                            {x?.title} :
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#101010",
                            }}
                          >
                            {x?.value === null ? (
                              <span style={{ color: "grey", opacity: "0.75" }}>
                                NA
                              </span>
                            ) : (
                              <span style={{ fontWeight: "600" }}>
                                {x?.value}
                              </span>
                            )}
                          </Typography>
                        </Box>
                      ))}
                      <Box
                        sx={{
                          width: { xs: "100%", md: "300px" },
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            // fontWeight: "500",
                            color: "#252525",
                          }}
                        >
                          Excercise Link :
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#101010",
                          }}
                        >
                          {!data?.excercise_link ? (
                            <span style={{ color: "grey", opacity: "0.75" }}>
                              NA
                            </span>
                          ) : (
                            <a
                              href={
                                data.excercise_link.startsWith("http://") ||
                                data.excercise_link.startsWith("https://")
                                  ? data.excercise_link
                                  : `https://${data.excercise_link}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                fontWeight: "600",
                                textDecoration: "none",
                                color: "#101010",
                              }}
                            >
                              {data.excercise_link}
                            </a>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                  >
                    <Tab
                      label="Trainer Plan"
                      {...a11yProps(0)}
                      sx={{
                        color: "black",
                        textTransform: "none",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    />
                    <Tab
                      label="User Progress"
                      {...a11yProps(1)}
                      sx={{
                        color: "black",
                        textTransform: "none",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <TrainerPlanTab value={value} id={id} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <TrainerPlanTab value={value} id={id} />
                </CustomTabPanel>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TrainingPlanDetails;
