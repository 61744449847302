import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Button, TablePagination, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AxiosInstance from "../../Common/Api/ApiHelper";
import { apiList } from "../../Common/Api/ApiList";
import moment from "moment";
import NoDataImg from "../../Common/NoData";
import Loader from "../../Common/Loader";
import { debounce } from "lodash";
import viewicon from "../../Assets/image/user/223_frame.png";
import { PathList } from "../../Common/Routes/Path";
import { useNavigate } from "react-router-dom";
import ErrorToaster from "../../Component/ErrorToaster";
import { toast } from "react-toastify";

const PAGE_SIZE_OPTIONS = [30];

export default function BookingListDataGrid(props) {
  const { orderStatusFilter, paymentFilter, search, endDate, startDate } =
    props;
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE_OPTIONS[0]);
  const [totalData, setTotalData] = useState(0);

  const StartDateMoment =
    startDate && moment(startDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");
  const EndDateMoment =
    endDate && moment(endDate?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)");

  const isStartDateFiltered =
    StartDateMoment && StartDateMoment.format("YYYY/MM/DD");
  const isEndDateFiltered = EndDateMoment && EndDateMoment.format("YYYY/MM/DD");

  const dateDifference =
    EndDateMoment && EndDateMoment.diff(StartDateMoment, "days");

  const fetchData = async (
    search = "",
    previousSearch = "",
    previousPaymentFilter = "",
    previousOrderStatusFilter = "",
    previousDateDiffernce = 0,
    page = 0,
    pageSize = PAGE_SIZE_OPTIONS[0]
  ) => {
    try
    {
      setLoading(true);
      let newPage = page;
      if (
        search !== previousSearch ||
        paymentFilter !== previousPaymentFilter ||
        orderStatusFilter !== previousOrderStatusFilter ||
        dateDifference !== previousDateDiffernce
      )
      {
        newPage = 0;
        setPage(0);
      }
      if (dateDifference !== null && dateDifference <= 0)
      {
        toast.error("End date must be later than the start date.");
        setLoading(false);
        setIsLoading(false);
        return;
      }
      let queryParams = new URLSearchParams({
        count: newPage * pageSize,
      });
      if (!(paymentFilter === "All"))
      {
        queryParams.append("payment", paymentFilter);
      }
      if (orderStatusFilter)
      {
        queryParams.append("session_status", orderStatusFilter);
      }
      if (dateDifference && dateDifference !== 0)
      {
        queryParams.append("from_date", isStartDateFiltered);
        queryParams.append("to_date", isEndDateFiltered);
      }
      if (search.length > 0)
      {
        queryParams.append("search", search);
      }

      const api = `${apiList.GetBookingList}?${queryParams.toString()}`;
      const res = await AxiosInstance.get(api);
      const Rowdata = res?.data?.r?.data?.map((data, i) => ({
        rowid: i + 1,
        ...data,
      }));
      if (Rowdata?.length > 0)
      {
        setdata(Rowdata);
        setTotalData(res?.data?.r?.total_count);
      } else
      {
        setdata([]);
        setTotalData(0);
      }
      setLoading(false);
      setIsLoading(false);
    } catch (error)
    {
      setLoading(false);
      setIsLoading(false);
      <ErrorToaster error={error} />;
    }
  };

  const paymentFilterRef = useRef(paymentFilter);
  const orderStatusFilterRef = useRef(orderStatusFilter);
  const searchRef = useRef(search);
  const dateDifferenceRef = useRef(dateDifference);
  const debouncedFetchData = debounce(() => {
    fetchData(
      search,
      searchRef.current,
      paymentFilterRef.current,
      orderStatusFilterRef.current,
      dateDifferenceRef.current,
      page,
      rowsPerPage
    );
    paymentFilterRef.current = paymentFilter;
    orderStatusFilterRef.current = orderStatusFilter;
    searchRef.current = search;
    dateDifferenceRef.current = dateDifference;
  }, 400);

  useEffect(() => {
    debouncedFetchData();
    return () => {
      debouncedFetchData.cancel();
    };
  }, [
    paymentFilter,
    page,
    rowsPerPage,
    orderStatusFilter,
    search,
    dateDifference,
  ]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const status = [
    { id: 1, status: "Pending" },
    { id: 2, status: "Confirmed" },
    { id: 3, status: "On the way" },
    { id: 4, status: "Arrived" },
    { id: 5, status: "Start session" },
    { id: 6, status: "Completed" },
    { id: 7, status: "Cancelled" },
  ];

  const columns =
    paymentFilter === "2"
      ? [
        {
          headerClassName: "super-app-theme--header",
          field: "rowid",
          headerName: "Sr.",
          width: 90,
          headerAlign: "center",
          renderCell: (params) => {
            return params.row.rowid + page * PAGE_SIZE_OPTIONS;
          },
        },
        {
          headerClassName: "super-app-theme--header",
          field: "id",
          headerName: "ID",
          width: 90,
          headerAlign: "center",
        },
        {
          headerClassName: "super-app-theme--header",
          field: "user_first_name",
          headerName: "User Name",
          flex: 1,
          minWidth: 220,
          headerAlign: "center",
          renderCell: (cell) =>
            cell?.row?.user_first_name
              ? cell?.row?.user_first_name + " " + cell?.row?.user_last_name
              : "-",
        },
        {
          headerClassName: "super-app-theme--header",
          field: "trainer_name",
          headerName: "Trainer Name",
          flex: 1,
          minWidth: 200,
          headerAlign: "center",
          renderCell: (cell) =>
            cell?.row?.trainer_first_name
              ? cell?.row?.trainer_first_name +
              " " +
              cell?.row?.trainer_last_name
              : "-",
        },

        {
          headerClassName: "super-app-theme--header",
          field: "created_at",
          headerName: "Date/Time",
          width: 200,
          headerAlign: "center",
          renderCell: (cell) => moment(cell?.row?.created_at).format("lll"),
        },

        {
          headerClassName: "super-app-theme--header",
          field: "req_status",
          headerName: "Status",
          type: "number",
          flex: 1,
          minWidth: 280,
          headerAlign: "center",
          renderCell: (cellValues) => {
            return cellValues?.row?.req_status ? (
              <Button
                variant="contained"
                style={{
                  background:
                    cellValues?.row?.req_status === -1
                      ? theme.palette.button.cancel
                      : cellValues?.row?.req_status === -2
                        ? theme.palette.button.cancel
                        : cellValues?.row?.req_status === -3 &&
                        theme.palette.button.otw,
                  fontWeight: "500",
                  textTransform: "none",
                  width: "220px",
                  color: "Black",
                }}
              >
                {cellValues?.row?.req_status === -1
                  ? "Rejected by User"
                  : cellValues?.row?.req_status === -2
                    ? "Not Accepted by Trainer"
                    : cellValues?.row?.req_status === -3 && "Unpaid"}
              </Button>
            ) : (
              "-"
            );
          },
        },
      ]
      : [
        {
          headerClassName: "super-app-theme--header",
          field: "rowid",
          headerName: "Sr.",
          width: 90,
          headerAlign: "center",
          renderCell: (params) => {
            return params.row.rowid + page * PAGE_SIZE_OPTIONS;
          },
        },
        {
          headerClassName: "super-app-theme--header",
          field: "id",
          headerName: "ID",
          width: 90,
          headerAlign: "center",
        },
        {
          headerClassName: "super-app-theme--header",
          field: "user_name",
          headerName: "User Name",
          flex: 1,
          minWidth: 220,
          headerAlign: "center",
        },
        {
          headerClassName: "super-app-theme--header",
          field: "trainer_name",
          headerName: "Trainer Name",
          flex: 1,
          minWidth: 200,
          headerAlign: "center",
        },
        {
          headerClassName: "super-app-theme--header",
          field: "booking_date",
          headerName: "Booking Date",
          width: 200,
          headerAlign: "center",
          renderCell: (cell) => {
            return (
              <span>{`${moment
                .utc(cell?.row?.booking_date)
                .local()
                .format("ll")}`}</span>
            );
          },
        },
        {
          headerClassName: "super-app-theme--header",
          field: "cat_name",
          headerName: "Category",
          flex: 1,
          minWidth: 200,
          headerAlign: "center",
          renderCell: (x) =>
            x?.row?.cat_list?.map((x, i) => (
              <span key={i}>{x.sub_cat_name}</span>
            )),
        },
        {
          headerClassName: "super-app-theme--header",
          field: "is_training_plan",
          headerName: "Training Plan Added?",
          width: 150,
          headerAlign: "center",
          renderCell: (cell) => {
            const trainingPlan = cell?.row?.is_training_plan;
            if (trainingPlan === 0)
            {
              return <span style={{ color: "red" }}>No</span>;
            } else if (trainingPlan === 1)
            {
              return <span style={{ color: "green" }}>Yes</span>;
            } else
            {
              return "-";
            }
          },
        },
        {
          headerClassName: "super-app-theme--header",
          field: "type",
          headerName: "Type",
          width: 150,
          headerAlign: "center",
          renderCell: (cell) => {
            if (cell?.row?.sub_type === 1)
            {
              return <span style={{ color: "#C37500" }}>Virtual</span>;
            } else
            {
              return <span style={{ color: "#1451B6" }}>Onsite</span>;
            }
          },
        },
        {
          headerClassName: "super-app-theme--header",
          field: "total_amount",
          headerName: "Price",
          flex: 1,
          minWidth: 150,
          headerAlign: "center",
          renderCell: (cell) => `$${cell?.row?.total_amount}`,
        },
        {
          headerClassName: "super-app-theme--header",
          field: "is_payment",
          headerName: "Payment",
          type: "string",
          flex: 1,
          minWidth: 100,
          headerAlign: "center",
          renderCell: (cell) => {
            return (
              <span
                style={{
                  color:
                    cell.row.is_payment === 1
                      ? "green"
                      : cell.row.is_payment === 0
                        ? "red"
                        : cell.row.is_payment === -1 && "green",
                }}
              >
                {cell.row.is_payment === 1
                  ? "Paid"
                  : cell.row.is_payment === 0
                    ? "Unpaid"
                    : cell.row.is_payment === -1 && "Refund"}
              </span>
            );
          },
        },
        {
          headerClassName: "super-app-theme--header",
          field: "session_status",
          headerName: "Status",
          type: "number",
          flex: 1,
          minWidth: 200,
          headerAlign: "center",
          renderCell: (cellValues) => {
            return (
              <Button
                variant="contained"
                style={{
                  background:
                    cellValues?.row?.session_status === 1
                      ? theme.palette.button.pending
                      : cellValues?.row?.session_status === 2
                        ? theme.palette.button.confirm
                        : cellValues?.row?.session_status === 3
                          ? theme.palette.button.otw
                          : cellValues?.row?.session_status === 4
                            ? theme.palette.button.arrived
                            : cellValues?.row?.session_status === 5
                              ? theme.palette.button.session
                              : cellValues?.row?.session_status === 6
                                ? theme.palette.button.complete
                                : theme.palette.button.cancel,
                  fontWeight: "500",
                  textTransform: "none",
                  width: "120px",
                  color: "Black",
                }}
              >
                {status[cellValues?.row?.session_status - 1]?.status}
              </Button>
            );
          },
        },
        {
          headerClassName: "super-app-theme--header",
          headerName: "View",
          sortable: false,
          width: 100,
          headerAlign: "center",
          renderCell: (e) => (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={viewicon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`${PathList.Booking_Details}/${e.row.id}`)
                }
              />
            </Box>
          ),
        },
      ];

  return (
    <Box sx={{ width: "100%", fontSize: "14px" }}>
      <Box
        sx={{
          width: "100%",
          height: "calc(100vh - 210px)",
          minHeight: "500px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "5px",
          p: 3,
        }}
      >
        <Box sx={{ height: `calc(100% - 50px)` }}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Typography sx={{ fontSize: "18px", fontWeight: "500" }}>
                Booking List
              </Typography>
              <DataGrid
                rows={data}
                pagination
                loading={loading}
                hideFooter
                disableColumnMenu={true}
                disableAutosize={true}
                slots={{ noRowsOverlay: NoDataImg }}
                disableColumnFilter={true}
                columns={columns}
                slotProps={{
                  loadingOverlay: {
                    variant: "skeleton",
                    noRowsVariant: "skeleton",
                  },
                }}
                hideFooterSelectedRowCount={true}
                getRowId={(e) => e?.rowid}
                onRowClick={(e) =>
                  e?.row?.session_status &&
                  navigate(`${PathList.Booking_Details}/${e?.row?.id}`)
                }
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "center",
                  ".MuiDataGrid-cell": {
                    textAlign: "center",
                    "&:focus": { outline: "none" },
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    wordWrap: "break-word",
                  },
                  ".MuiDataGrid-cell:focus": { outline: "none" },
                  ".MuiDataGrid-columnHeaderTitle": {
                    fontWeight: "600",
                    color: "white",
                  },
                  "& .super-app-theme--header": {
                    background: theme.palette.primary.Blend,
                  },
                }}
                pageSize={rowsPerPage}
              />

              <TablePagination
                component="div"
                count={totalData}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPageOptions={PAGE_SIZE_OPTIONS}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
