import Typography from "@mui/material/Typography";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AxiosInstance from "../../../../Common/Api/ApiHelper";
import { apiList } from "../../../../Common/Api/ApiList";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";

//   ------- STATUS FOR ACTION ---------
// -  REQUEST = 2
// -  APPROVE = 1
// -  REJECT = -3
// -  RESTRICT = -2
// -  UNRESTRICT = 1

export default function AccordionComponent({
  data,
  id,
  getData,
  userId,
  expanded,
  handleChange,
  setSelectedCat,
  selectedCat,
  handleChangeAllButton,
}) {
  // const [isActionLoading, setIsActionLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleCategoryAction = async (userId, cat_id, sub_cat_id, status) => {
    try {
      // setIsActionLoading(true);
      let f = new FormData();
      f.append("u_id", userId);
      f.append("cat_id", cat_id);
      f.append("sub_cat_id", sub_cat_id);
      f.append("status", status);
      const response = await AxiosInstance.post(
        apiList?.TrainerCategoryAction,
        f
      );
      if (response?.data?.s) {
        getData();
      } else {
        toast.error(response?.data?.m);
      }
      // setIsActionLoading(false);
    } catch (error) {
      console.log(error);
      // setIsActionLoading(false);
    }
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handleAction = (action) => {
    if (selectedItem) {
      handleCategoryAction(
        userId,
        selectedItem?.cat_id,
        selectedItem?.id,
        action
      );
    }
    handleClose();
  };

  const getTitleWithStatus = (status) => {
    if (status === 1) {
      return {
        title: " (Approved)",
        color: "#008000",
      };
    } else if (status === 2) {
      return {
        title: " (Requested)",
        color: "#eac810",
      };
    } else if (status === -2) {
      return {
        title: " (Restricted)",
        color: "#de3131",
      };
    } else if (status === -3) {
      return {
        title: " (Rejected)",
        color: "#de3131",
      };
    } else return {};
  };
  // multiple selection

  const isMainSelected = (children) => {
    return (
      // selectedItems.includes(mainId) ||
      children.every((child) => selectedCat.includes(child.id))
    );
  };

  const toggleMain = (children) => {
    const allChildIds = children.map((child) => child.id);
    const isSelected = children.every((child) =>
      selectedCat.includes(child.id)
    );
    setSelectedCat((prev) =>
      isSelected
        ? prev.filter((id) => !allChildIds.includes(id))
        : [...prev, ...allChildIds]
    );
    handleChangeAllButton(selectedCat);
  };

  const toggleChild = (childId) => {
    setSelectedCat((prev) => {
      if (prev.includes(childId)) {
        return prev.filter((id) => id !== childId);
      }
      return [...prev, childId];
    });
    handleChangeAllButton(selectedCat);
  };

  return (
    <Accordion
      expanded={data?.sub_cat?.length > 0 && expanded === `sub-cat-${id}`}
      onChange={data?.sub_cat?.length > 0 && handleChange(`sub-cat-${id}`)}
      disableGutters
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary
        expandIcon={
          data?.sub_cat?.length > 0 && (
            <ExpandMoreIcon style={{ color: "#000" }} />
          )
        }
        aria-controls={`sub-cat-${id}-content`}
        id={`sub-cat-${id}-header`}
        sx={{
          background: "#c9deff",
          borderRadius: "4px",
          position: expanded === `sub-cat-${id}` ? "sticky" : "static",
          top: 50,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",

            width: "100%",
          }}
        >
          <Typography sx={{ fontWeight: "500", color: "#000" }}>
            {id}. {data?.cat_name}
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isMainSelected(data?.sub_cat)}
                  onChange={() => toggleMain(data?.sub_cat)}
                  style={{
                    color: "black",
                  }}
                />
              }
              label={
                isMainSelected(data?.sub_cat) ? `Deselect All` : "Select All"
              }
            />
          </FormGroup>
        </Box>
      </AccordionSummary>

      <AccordionDetails

      // ref={ref}
      >
        {data?.sub_cat?.map((sub,i) => {
          return (
            <Box
            key={i}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                key={sub?.id + "child-sub_cat"}
                width={"100%"}
                sx={{
                  outline: "1px solid #CEE5FF",
                  marginBottom: "10px",
                  padding: "10px",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                  transition: "all 0.1s ease",
                  "&:hover": {
                    // transform: "scale(0.995)",
                    outline: "1px solid #3c75d5",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "#3f77D5",
                    }}
                  >
                    {sub?.sub_cat_name}
                    <span
                      style={{
                        fontSize: "14px",
                        color: getTitleWithStatus(sub?.status)?.color,
                      }}
                    >
                      {getTitleWithStatus(sub?.status)?.title}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "500",
                      display: sub?.cat_id === 15 ? "none" : "block",
                    }}
                  >
                    <span style={{ color: "GrayText" }}>Instant :</span> $
                    {sub?.instant_price}
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
                    <span style={{ color: "GrayText" }}>Pre Booking :</span> $
                    {sub?.pre_price}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton onClick={(e) => handleClick(e, sub)}>
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              </Box>
              <Checkbox
                checked={selectedCat.includes(sub.id)}
                onChange={() => toggleChild(sub.id)}
                style={{
                  color: "#3c75d5",
                  display: selectedCat.includes(sub.id) ? "flex" : "none  ",
                }}
              />
            </Box>
          );
        })}
        <Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {(selectedItem?.status === 2 || selectedItem?.status === -3) && (
              <MenuItem onClick={() => handleAction(1)}>Approve</MenuItem>
            )}
            {(selectedItem?.status === 2 || selectedItem?.status === 1) && (
              <MenuItem onClick={() => handleAction(-3)}>Reject</MenuItem>
            )}
            {selectedItem?.status === 1 && (
              <MenuItem onClick={() => handleAction(-2)}>Restrict</MenuItem>
            )}
            {selectedItem?.status === -2 && (
              <MenuItem onClick={() => handleAction(1)}>Unrestrict</MenuItem>
            )}
          </Menu>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
