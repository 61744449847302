export const PathList = {
  Login: "/",
  Dashboard: "/dashboard",
  Booking: "/booking",
  Booking_Details: "/booking/details",
  Trainer: "/trainer",
  TrainerDetails: "/trainer/details",
  Notification: "/notification",
  PaymentHistory: "/payment/history",
  Support: "/support",
  Chat: "/chat",
  UserManagement: "/user",
  UserDetails: "/user/details",
  FAQs: "/faqs",
  AppIssue: "/appissue",
  Setting: "/setting",
  PlanDetails:"/plan/details",
  request:"/requests"
};
