import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import dummyimage from "../Assets/image/user/9720009.jpg";
import FetchSignedUrl from "./URL_Generator";

const ProfileImageCell = ({ imageUrl }) => {
  const [signedUrl, setSignedUrl] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      if (imageUrl) {
        const path = imageUrl.split(
          "https://instatrainme-api.s3.amazonaws.com/"
        )[1];
        const signed = await FetchSignedUrl({ path });
        setSignedUrl(signed);
      }
    };

    fetchUrl();
  }, [imageUrl]);

  return (
    <Box
      sx={{
        height: "100%",
        padding: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "17px",
      }}
    >
      <img
        src={signedUrl || dummyimage}
        height={40}
        width={40}
        alt="Profile"
        style={{ borderRadius: "12px", objectFit: "cover" }}
      />
    </Box>
  );
};

export default ProfileImageCell;
