import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import dummyimage from "../Assets/image/image.png";
import FetchSignedUrl from "./URL_Generator";

const AttachmentCell = ({ attachmentUrl, handleImageClick }) => {
  const [signedUrl, setSignedUrl] = useState(null);

  useEffect(() => {
    const fetchAttachmentUrl = async () => {
      if (attachmentUrl)
      {
        const path = attachmentUrl.split(
          "https://instatrainme-api.s3.amazonaws.com/"
        )[1];
        const signed = await FetchSignedUrl({ path });
        setSignedUrl(signed);
      }
    };

    fetchAttachmentUrl();
  }, [attachmentUrl]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={signedUrl || dummyimage}
        alt="Attachment"
        style={{
          height: "40px",
          width: "50px",
          borderRadius: "10px",
          border: "1px solid #E0E0E0",
          objectFit: signedUrl ? "cover" : 'contain',
          cursor: "pointer",
        }}
        onClick={() => {
          handleImageClick(signedUrl);
        }}
      />
    </Box>
  );
};

export default AttachmentCell;
