import { Box, MenuItem, Select, useTheme, Divider } from "@mui/material";
import React, { useEffect } from "react";
import userTypeLogo from "../../Assets/image/dash/Banknote.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateLogo from "../../Assets/image/dash/Calendar Search.png";
import moment from "moment";

export default function PaymentDropItemComponent(props) {

    const theme = useTheme();

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };
    const {
        paymentFilter,
        setPaymentFilter,
        endDate,
        setEndDate,
        startDate,
        setStartDate,
    } = props;

    const StartDateMoment = startDate && moment(
        startDate?.$d,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
    );
    const EndDateMoment = endDate && moment(
        endDate?.$d,
        "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)"
    );

    const dateDifference = EndDateMoment && EndDateMoment.diff(StartDateMoment, "days");

    useEffect(() => { }, [dateDifference, startDate, EndDateMoment]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: { sm: "2rem", lg: "1rem" },
                    justifyContent: "end",
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        padding: "8px",
                        width: {
                            xs: "100%",
                            sm: "200px",
                            md: "160px",
                            lg: "180px",
                            xl: "200px",
                        },
                        borderRadius: "8px",
                        fontSize: "14px",
                        boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "start",
                            gap: "0.5rem",
                            pb: "0.15rem",
                            color: theme.palette.text.primary,
                        }}
                    >
                        <img src={userTypeLogo} height={17} width={17} alt="" srcSet="" />
                        Payment
                    </Box>

                    <Select
                        value={paymentFilter}
                        fullWidth
                        sx={{
                            backgroundColor: "#f5f5f5",
                            borderRadius: "8px",
                            ".MuiSelect-select": {
                                padding: "8px 12px",
                            },
                        }}
                        onChange={(e) => {
                            const {
                                target: { value },
                            } = e;

                            setPaymentFilter(value);
                        }}
                    >
                        {[
                            {
                                id: "All",
                                status: "All",
                            },
                            {
                                id: "1",
                                status: "Paid",
                            },

                            {
                                id: "-1",
                                status: "Refund",
                            },
                        ].map((x, i) => {
                            return (
                                <MenuItem key={i} value={x?.id} sx={{ padding: "7px" }}>
                                    {x?.status}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>

                <Box
                    sx={{
                        padding: "8px",
                        width: { xs: "100%", sm: "300px" },
                        borderRadius: "8px",
                        fontSize: "14px",
                        boxShadow: "0 3px 10px 0 rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",

                            gap: "0.5rem",
                            pb: "0.15rem",
                            color: theme.palette.text.primary,
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "start",
                                gap: "0.5rem",
                            }}
                        >
                            <img src={DateLogo} height={17} width={17} alt="" srcSet="" />
                            Date
                        </Box>
                        <Box
                            onClick={() => {
                                setStartDate(null);
                                setEndDate(null);
                            }}
                            sx={{
                                cursor: "pointer",
                                color: "#3498db",
                                display: dateDifference !== null ? "block" : "none",
                                "&:hover": { fontWeight: "500" },
                            }}
                        >
                            Clear
                        </Box>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                            sx={{
                                padding: "3px",
                                width: "100%",
                                borderRadius: "8px",
                                fontSize: "14px",
                                display: "flex",
                                border: "0.1rem solid #eee",
                                background: theme.palette.background.thead,
                            }}
                        >
                            <DatePicker
                                //   label="Start Date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                renderInput={(startProps) => <input {...startProps.inputProps} />}
                                format="MM/DD/YY"
                                sx={{
                                    // border:"none"
                                    "& fieldset": {
                                        border: "none",
                                    },
                                }}
                            />
                            <Divider />
                            <DatePicker
                                //   label="End Date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                format="MM/DD/YY"
                                renderInput={(endProps) => <input {...endProps.inputProps} />}
                                sx={{
                                    // border:"none"
                                    "& fieldset": {
                                        border: "none",
                                    },
                                }}
                            />
                        </Box>
                    </LocalizationProvider>
                </Box>
            </Box>


        </>
    );
}
