
import React, { useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import Loader from "../Common/Loader";
import { toast } from "react-toastify";
import FetchSignedUrl from "./URL_Generator";
import dummyImage from "../Assets/image/user/9720009.jpg";
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  maxHeight: "95vh",
  minHeight: "50vh",
  overflowY: "auto",
  minWidth: "300px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "12px",
};

const SignedUrlModal = ({ path }) => {
  const [signedUrl, setSignedUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getSignedUrl = async () => {
      if (path) {
        setLoading(true);
        try {
          const url = await FetchSignedUrl({ path });
          setSignedUrl(url);
        } catch (error) {
          console.error("Error generating pre-signed URL", error);
          toast.error("Error generating pre-signed URL");
        }
        setLoading(false);
      }
    };

    getSignedUrl();
  }, [path]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {signedUrl ? (
        <img
          onClick={handleOpen}
          src={signedUrl}
          alt=""
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            border: "1px solid #E0E0E0",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      ) : (
        <img
          src={dummyImage}
          alt=""
          style={{
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            border: "1px solid #E0E0E0",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          {!loading ? (
            <img
              src={signedUrl}
              alt="Profile"
              style={{
                maxWidth: "100%",
                maxHeight: "80vh",
                borderRadius: "12px",
              }}
            />
          ) : (
            <Loader />
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default SignedUrlModal;
